import {
  ProfessionalRole,
} from '../generated/graphql';

import { Person } from '@material-ui/icons';
import { OrganizationIcon, DoctorIcon, PatientIcon, AssistantIcon } from './icons';
import { CustomIcon } from '../components/shared/custom-icon';

export const getProfileIcon = (profile: { __typename?: string }) => {
  return getRoleIcon(profile.__typename)
}

export const getRoleIcon = (role?: string) => {
  switch (role) {
    case ProfessionalRole.Medic: return <CustomIcon Icon={DoctorIcon} />;
    case ProfessionalRole.Assistant: return <CustomIcon Icon={AssistantIcon} />;
    case ProfessionalRole.OrganizationSa: return <OrganizationIcon />;
    case 'PatientUser': return <PatientIcon />;
    default: return <Person />;
  }
}
