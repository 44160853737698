import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AddProfileIcon } from '../utils/icons';
// import LockIcon from '@material-ui/icons/Lock';
// import SettingsIcon from '@material-ui/icons/Settings';
// import EditIcon from '@material-ui/icons/Edit';
// import FolderSharedIcon from '@material-ui/icons/FolderShared';

export const profileRoutes: RouteType[] = [
  {
    key: RoutesKeys.profile,
    component: asyncLoad(() => import('../containers/profile/profile.container')),
    path: '/profilo',
    exact: true,
    config: {
      label: 'Profilo',
      title: 'Profilo',
      icon: AccountCircleIcon,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.createProfile,
    component: asyncLoad(() => import('../containers/profile/add-profile.container')),
    path: '/add-profile',
    exact: true,
    config: {
      label: 'Aggiungi profilo',
      title: 'Aggiungi profilo',
      icon: AddProfileIcon,
      navBar: false,
      showBack: false,
      scopes: [],
    },
  },
  // {
  //   key: RoutesKeys.settings,
  //   component: asyncLoad(() => import('../containers/not-implemented')),
  //   path: '/impostazioni',
  //   exact: true,
  //   config: {
  //     label: 'Impostazioni',
  //     title: 'Impostazioni',
  //     icon: SettingsIcon,
  //     navBar: true,
  //     showBack: true,
  //     scopes: [],
  //   },
  // },
  // {
  //   key: RoutesKeys.personalData,
  //   component: asyncLoad(() => import('../containers/edit-personal-data/edit-personal-data.container')),
  //   path: '/dati-anagrafici',
  //   exact: true,
  //   config: {
  //     label: 'Modifica dati anagrafici',
  //     title: 'Modifica dati anagrafici',
  //     icon: EditIcon,
  //     navBar: true,
  //     showBack: true,
  //     scopes: [],
  //   },
  // },
  // {
  //   key: RoutesKeys.medicalHistory,
  //   component: asyncLoad(() => import('../containers/not-implemented')),
  //   path: '/anamnesi',
  //   exact: true,
  //   config: {
  //     label: 'Anamnesi',
  //     title: 'Anamnesi',
  //     icon: FolderSharedIcon,
  //     navBar: true,
  //     showBack: true,
  //     scopes: [],
  //   },
  // },
];
