import { FC } from 'react';
import { useChatPopup } from '../../contexts/chat.context';
import { ChatHeader } from './chat-header';
import { SingleChat } from './single-chat';
import { makeStyles, Paper, Portal } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popup: {
    position: 'fixed',
    bottom: 0,
    right: 10,
    height: 450,
    width: 300,
    backgroundColor: 'white',
    zIndex: 1000000,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      width: '100vw',
      right: 0,
      left: 0,
      bottom: 0,
      top: 0,
    },
  },
  singleChat: {
    height: 450 - 48, // toolbar height
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 48px)',
    },
  },
}))

export const ChatPopup: FC = () => {
  const { close, destUser, error } = useChatPopup();
  const classes = useStyles();

  if (!destUser) {
    return null;
  }

  return (
    <Portal container={document.body}>
      <Paper className={classes.popup}>
        <ChatHeader destUser={destUser} onClose={close}/>
        <div className={classes.singleChat}>
          <SingleChat destUser={destUser} error={error}/>
        </div>
      </Paper>
    </Portal>
  )
}
