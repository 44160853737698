import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from '@hu-care/react-layout';

import {
  PatientDataFragment,
  PatientDocument,
  PatientQuery,
  PatientQueryVariables,
  PatientsDocument,
  PatientsQuery,
  PatientsQueryVariables,
  PatientType,
} from '../generated/graphql';
import { getRoute, RoutesKeys } from '../routes';
import { useRedirect } from '../hooks/useRedirect';

export interface PatientContextType {
  selectedPatient: PatientDataFragment | null;
  loading: boolean;
}

const PatientContext = createContext<PatientContextType>({
  selectedPatient: null,
  loading: false,
});

export const usePatient = () => useContext(PatientContext);

export const PatientProvider: FC = ({ children }) => {
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
  const redirect = useRedirect();

  const { data, loading: allPLoading } = useQuery<PatientsQuery, PatientsQueryVariables>(PatientsDocument);
  const { data: patientData, loading: pLoading } = useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, {
    variables: {
      id: selectedPatientId!,
    },
    skip: !selectedPatientId,
  });

  useEffect(() => {
    if (data?.patientProfiles) {
      let ownerPatient = data.patientProfiles.find(p => p.type === PatientType.Owner);
      if (!ownerPatient) {
        ownerPatient = data.patientProfiles[0];
      }
      if (ownerPatient) {
        setSelectedPatientId(ownerPatient.patientId);
      }
    }
  }, [data, setSelectedPatientId]);

  const loading = allPLoading || pLoading;
  const selectedPatient = useMemo(() => patientData?.patient || null, [patientData]);

  const value = useMemo<PatientContextType>(() => ({
    selectedPatient,
    loading,
  }), [selectedPatient, loading]);

  useEffect(() => {
    if (data?.patientProfiles && data.patientProfiles.length === 0) {
      redirect(getRoute(RoutesKeys.createProfile))
    }
  }, [redirect, data])

  if (loading) {
    return <Loading />
  }

  return (
    <PatientContext.Provider value={value}>
      {children}
    </PatientContext.Provider>
  )
}
