import React, { useEffect, useCallback, useState } from 'react';
import { formatRelative, formatDistanceToNow, isToday, parseISO } from 'date-fns';
import { it } from 'date-fns/esm/locale';
import {
  Typography,
  Grid,
  Chip,
  Link,
  Tooltip,
} from '@material-ui/core'
import clsx from 'clsx';
import { Check, AttachFile } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MediaUiMessage, UiMessage } from '@hu-care/chat-client';
import { useInView } from 'react-intersection-observer';
import { formatDate } from '@hu-care/react-utils';
import { isFileAccepted } from '../../utils/upload';

export type RequestMessageProps = {
  color?: string;
  message: UiMessage;
  showTriangle?: boolean;
  showDate?: boolean;
  float?: 'left' | 'right';
}

const isMedia = (message: UiMessage): message is MediaUiMessage => {
  return message.type === 'media';
};

const shouldDisplayMedia = ({ media }: MediaUiMessage) => {
  if (media.mime === 'application/pdf') return false;

  if (isFileAccepted({ name: media.name, type: media.mime })) {
    const [, type] = media.mime.split('/');
    return type === 'gif' || media.size <= 1048576 * 3 // 3MB
  }
  return false;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: (props: Partial<RequestMessageProps>) => ({
      marginBottom: props.showTriangle ? theme.spacing(2) : '4px',
    }),
    messageBox: (props: Partial<RequestMessageProps>) => ({
      float: props.float,
      textAlign: 'left',
      wordBreak: 'break-word',
      width: 'fit-content',
      maxWidth: '90vw',
      opacity: '1',
      minWidth: '50px',
      padding: '5px 12px',
      position: 'relative',
    }),
    messageAuthor: (props: Partial<RequestMessageProps>) => ({
      fontWeight: theme.typography.fontWeightBold,
      textAlign: props.float,
      opacity: '1',
      fontSize: '14pt',
      color: props.color,
      whiteSpace: 'pre-line',
    }),
    transparentBg: (props: Partial<RequestMessageProps>) => ({
      background: props.color,
      opacity: '0.2',
      width: '100%',
      position: 'absolute',
      height: '100%',
      display: 'inline-block',
      left: 0,
      top: 0,
      borderRadius: '12px',
    }),
    receivedAt: (props: Partial<RequestMessageProps>) => ({
      display: 'flex',
      marginTop: props.showTriangle && props.message?.type === 'text'
        ? theme.spacing(1.5)
        : 0,
      justifyContent: props.float === 'left' ? 'flex-start' : 'flex-end',
      fontSize: '12pt',
      alignItems: 'center',
      opacity: 0.8,
      '& svg': {
        marginRight: '2px',
        opacity: 0.8,
        fontSize: '12pt',
      },
    }),
    triangle: (props: Partial<RequestMessageProps>) => ({
      width: 0,
      height: 0,
      position: 'absolute',
      [props.float === 'left' ? 'left' : 'right']: '14px',
      bottom: '-12px',
      opacity: '0.2',
      [props.float === 'left' ? 'borderRight' : 'borderLeft']: '24px solid transparent',
      [props.float === 'left' ? 'borderLeft' : 'borderRight']: '0 solid transparent',
      borderTop: `12px solid ${props.color}`,
    }),
    attachmentIcon: {
      padding: '2px 0',
      height: '14pt !important',
    },
    chipContainer: (props: Partial<RequestMessageProps>) => ({
      display: 'flex',
      justifyContent: props.float === 'left' ? 'flex-start' : 'flex-end',
      marginLeft: 0,
      marginRight: 0,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    }),
    chip: (props: Partial<RequestMessageProps>) => ({
      '& .MuiChip-label': {
        maxWidth: '30ch',
        paddingLeft: '6px !important',
        paddingRight: '16px !important',
      },
    }),
    mediaPlaceholder: (props: Partial<RequestMessageProps>) => ({
      // maxHeight: 130,
      minHeight: 100,
      width: 200,
      background: theme.palette.grey.A100,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    }),
    media: {
      width: '100%',
      minHeight: '100%',
      // objectFit: 'cover',
    },
  }),
);

const formatRelativeLocale: Record<string, string> = {
  yesterday: '\'ieri alle\' hh:mm',
  other: 'dd/MM/yyyy \'alle\' hh:mm',
  lastWeek: 'dd/MM/yyyy \'alle\' hh:mm',
  tomorrow: 'Pp',
  nextWeek: 'Pp',
};

export const ChatBubble: React.FC<RequestMessageProps> = ({
  color,
  showDate,
  message,
  showTriangle,
  float = 'left',
}) => {
  const classes = useStyles({ color, float, showTriangle, message });

  if (showDate === undefined) {
    showDate = showTriangle;
  }

  const [mediaUrl, setMediaUrl] = useState<string>('');

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isMedia(message) && inView && !mediaUrl) {
      message.media.urlFn().then(url => setMediaUrl(url));
    }
  }, [message, inView, mediaUrl]);

  const getRelativeDate = useCallback(() => {
    const date = parseISO(message.date)

    if (isToday(date)) {
      return formatDistanceToNow(date, { locale: it, addSuffix: true })
    }

    return formatRelative(date, new Date(), {
      locale: {
        ...it,
        formatRelative: (relativeDate: string) => {
          return formatRelativeLocale[relativeDate]
        },
      },
    })
  }, [message])

  const showAsLink = isMedia(message) && !shouldDisplayMedia(message);

  return (
    <Grid container className={classes.root} ref={ref}>
      <Tooltip title={message.date && formatDate(message.date)}>
        <>
          {message.type === 'media'
            ? <Grid item xs={12}>
              {showAsLink ? (
                <Link underline="hover" href={mediaUrl} target="_blank" className={classes.chipContainer}>
                  <Chip
                    className={classes.chip}
                    avatar={<AttachFile className={classes.attachmentIcon} />}
                    label={message.media.name}
                  />
                </Link>
              ) : (
                <div className={classes.chipContainer}>
                  <div className={clsx(classes.mediaPlaceholder, 'chat-bubble-media')}>
                    {mediaUrl && <img src={mediaUrl} alt="" className={classes.media} />}
                  </div>
                </div>
              )}
            </Grid>
            : <Grid item xs={12}>
              <Typography className={classes.messageBox}>
                {message.body}
                <span className={classes.transparentBg}/>
                {showTriangle && <span className={classes.triangle}/>}
              </Typography>
            </Grid>
          }
          { showDate && message?.date &&
          <Grid item xs={12} className={classes.receivedAt}>
            <Check />
            <Typography variant="body2">
              { getRelativeDate() }
            </Typography>
          </Grid>
          }
        </>
      </Tooltip>
    </Grid>
  );
};
