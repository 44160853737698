import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ClientOAuth2, { Token } from 'client-oauth2';
import { decodeObject, encodeObject, getPkce } from '../utils/crypto';
import { useHistory } from 'react-router';
import { Loading } from '@hu-care/react-layout';
import { decodeJwt } from '@hu-care/react-utils';
import { Capacitor, Plugins } from '@capacitor/core';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { getRoute, RoutesKeys } from '../routes';

const { Storage } = Plugins;

const STORAGE_TOKEN_KEY = '@patient:refreshToken';
const STORAGE_VERIFIER_KEY = '@patient:pkceVerifier';

declare const window: any;

export interface AuthUser {
  name: string;
  surname: string;
  picture: string;
  email: string;
  taxId: string;
  phone: string;
  sub: string;
}

export interface AuthContext {
  ready: boolean;
  token: Token | null;
  user: AuthUser | null;
  refreshToken: (token?: string) => Promise<Token | null>;
  getToken: () => string;
  logout: () => Promise<void>;
  settingsUrl: string;
}

const redirectUri = `${window.location.origin}/callback`;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID!;
const accessTokenUri = new URL('/h/oauth2/token', process.env.REACT_APP_AUTH_URL).toString();
const revokeUri = new URL('/h/oauth2/revoke', process.env.REACT_APP_AUTH_URL).toString();
const logoutUrl = new URL('/k/self-service/browser/flows/logout', process.env.REACT_APP_AUTH_URL).toString();
const settingsUrl = new URL('/settings', process.env.REACT_APP_AUTH_URL).toString();

const AuthCtx = createContext<AuthContext>({
  ready: false,
  user: null,
  token: null,
  getToken: () => '',
  refreshToken: token => Promise.resolve(null),
  logout: () => Promise.resolve(),
  settingsUrl,
});

const client = new ClientOAuth2({
  clientId,
  accessTokenUri,
  redirectUri,
  authorizationUri: new URL('/h/oauth2/auth', process.env.REACT_APP_AUTH_URL).toString(),
  scopes: ['openid', 'email', 'profile', 'offline_access'],
});

/**
 * Create the authorize url
 * This app use the PKCE authorize flow because it is a client only app
 * @param returnTo
 * @param extraState
 */
async function getAuthUri(returnTo: string, extraState: Record<string, any> = {}) {
  const state = encodeObject({ returnTo, ...extraState });
  const { verifier, challenge } = await getPkce();
  await Storage.set({
    key: STORAGE_VERIFIER_KEY,
    value: verifier,
  });
  // localStorage.setItem(STORAGE_VERIFIER_KEY, verifier);
  return client.code.getUri({
    query: {
      code_challenge: challenge,
      code_challenge_method: 'S256',
      state,
    },
  });
}

/**
 * Get a fresh access token from a refresh token
 * @param token
 */
async function getNewToken(token: string) {
  return fetch(accessTokenUri, {
    method: 'POST',
    body: new URLSearchParams({
      refresh_token: token,
      client_id: clientId,
      grant_type: 'refresh_token',
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
  }).then(res => {
    return res.json()
    .then(data => {
      if (!res.ok) {
        throw data;
      }
      return data;
    });
  });
}

async function redirectToLogout(cb: (url: string | ClientOAuth2.UrlObject, state?: string) => void) {
  await Storage.remove({ key: STORAGE_TOKEN_KEY });
  const uri = new URL(logoutUrl);
  uri.searchParams.set('return_to', await getAuthUri(window.location.pathname));

  const browser = InAppBrowser.create(uri.toString(), Capacitor.isNative ? '_blank' : '_self', {
    toolbar: 'no',
    hideurlbar: 'yes',
    footer: 'no',
    cleardata: 'yes',
    clearsessioncache: 'yes',
    presentationstyle: 'fullscreen',
  });
  let browserSub: import('rxjs').Subscription | null;
  if (Capacitor.isNative) {
    browserSub = browser.on('loadstart')
    .subscribe(event => {
      if (event.url.startsWith(window.location.origin)) {
        browser.close();
        browserSub?.unsubscribe();
        const parsed = new URL(event.url);
        const code = parsed.searchParams.get('code');
        const state = parsed.searchParams.get('state')
        if (code) {
          cb(event.url, state || undefined);
        } else {
          redirectToAuthorize(cb);
        }
      }
    })
  }
  browser.show();

  return () => {
    if (browserSub && !browserSub.closed) {
      browserSub.unsubscribe();
    }
  };
}

/**
 * Redirect the user to the auth server to perform an auth code flow
 * The user will be prompted with the login form and, after successful authenticate,
 * it will be redirected to the app callback with a code query parameter
 * the app will then exchange the code for an access token to use against the api
 */
async function redirectToAuthorize(cb: (url: string | ClientOAuth2.UrlObject, state?: string) => void) {
  await Storage.remove({ key: STORAGE_TOKEN_KEY });
  const authUrl = await getAuthUri(window.location.pathname);
  const browser = InAppBrowser.create(authUrl, Capacitor.isNative ? '_blank' : '_self', {
    toolbar: 'no',
    cleardata: 'yes',
    clearsessioncache: 'yes',
  });
  let browserSub: import('rxjs').Subscription | null;
  if (Capacitor.isNative) {
    browserSub = browser.on('loadstart')
      .subscribe(event => {
        if (event.url.startsWith(window.location.origin)) {
          browser.close();
          browserSub?.unsubscribe();
          const parsed = new URL(event.url);
          const code = parsed.searchParams.get('code');
          const state = parsed.searchParams.get('state')
          if (code) {
            cb(event.url, state || undefined);
          } else {
            redirectToAuthorize(cb);
          }
        }
      });
  }
  browser.show();

  return () => {
    if (browserSub && !browserSub.closed) {
      browserSub.unsubscribe();
    }
  };
}

/**
 * Revoke a token
 * If a refresh token is revoked, the related access token is revoked too
 * @param token
 */
async function revokeToken(token: string) {
  return fetch(revokeUri, {
    method: 'POST',
    body: new URLSearchParams({
      token,
      client_id: clientId,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
  }).then(res => {
    if (!res.ok) {
      return res.json()
      .then(err => {
        throw err;
      });
    }
    return null;
  });
}

export const useAuth = () => useContext(AuthCtx);

export const AuthProvider: FC<{ skipAll?: boolean }> = ({ children, skipAll }) => {
  const [token, setToken] = useState<Token | null>(null);
  const { location, push, replace } = useHistory();

  const getTokenFromCode = useCallback(async (uri: string | ClientOAuth2.UrlObject, state?: string) => {
    return client.code.getToken(uri, {
      body: {
        code_verifier: (await Storage.get({ key: STORAGE_VERIFIER_KEY })).value || '',
      },
    })
    .then(async data => {
      // We can add the desired return to uri in the auth flow state and retrieve it here,
      // redirecting the user to the correct uri
      if (state) {
        const { returnTo = '/' } = decodeObject<{ returnTo: string }>(state);
        if (data.refreshToken) {
          // Save the refresh token to renew the auth session in future
          await Storage.set({
            key: STORAGE_TOKEN_KEY,
            value: data.refreshToken,
          });
        }
        // Go to the requested url or home /
        push(returnTo);
      }
      // Store the token in memory
      setToken(data);
    })
    .catch(err => {
      console.error(err);
      setToken(null);
    })
    .finally(() => {
      return Storage.remove({ key: STORAGE_VERIFIER_KEY });
    });
  }, [push, setToken]);

  const handleAuthorize = useCallback((url: string | ClientOAuth2.UrlObject, state?: string) => {
    return getTokenFromCode(url, state);
  }, [getTokenFromCode]);

  /**
   * Logout a user
   * Revoke its refresh token (and the related access token),
   * remove it from storage
   * redirect the user to the auth server logout endpoint
   */
  const logout = useCallback(async () => {
    const onRevoke = async () => {
      replace(getRoute(RoutesKeys.dashboard));
      await redirectToLogout(handleAuthorize);
      setToken(null);
    };
    if (token) {
      return revokeToken(token.refreshToken)
        .catch(err => {
          console.error(err);
        })
        .then(() => {
          return onRevoke();
        });
    } else {
      await onRevoke();
    }
  }, [token, handleAuthorize, setToken, replace]);

  const refreshAccessToken = useCallback(async (refreshToken?: string) => {
    if (skipAll) {
      throw new SkipAllError('Cannot refresh token without OAuth2');
    }
    if (!refreshToken) {
      // await redirectToAuthorize();
      throw new Error('Missing refresh token')
    }
    return getNewToken(refreshToken)
    .then(data => {
      // Refresh token was valid, save the new access token in memory
      // Update the storage with the new refresh token
      const newToken = client.createToken(data);
      setToken(newToken);
      return Storage.set({
        key: STORAGE_TOKEN_KEY,
        value: newToken.refreshToken,
      }).then(() => newToken);
    })
    .catch(err => {
      // Refresh token was invalid, redirect to the auth server
      console.error(err);
      setToken(null);
      redirectToAuthorize(handleAuthorize);
      return null;
    });
  }, [setToken, skipAll, handleAuthorize]);

  useEffect(() => {
    let browserSub: Promise<() => void> | null;

    if (skipAll) {
      return;
    }
    const { code, state } = location.query;

    // We are in the authorization callback url, the code is needed to get an access token
    // This will occur only on web
    if (code) {
      getTokenFromCode(location, state);
    } else {
      // Check if there is an old refresh token in storage
      Storage.get({ key: STORAGE_TOKEN_KEY })
        .then(({ value: refreshToken }) => {
          if (!refreshToken) {
            // No refresh token found, redirect to the auth server
            browserSub = redirectToAuthorize(handleAuthorize);
          } else {
            // A token is found, try to exchange it for a new access token
            refreshAccessToken(refreshToken);
          }
        });
    }

    return () => {
      if (browserSub) {
        browserSub.then(r => r());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useMemo<AuthUser | null>(() => {
    if (token?.data.id_token) {
      return decodeJwt(token.data.id_token);
    }
    return skipAll ? {
      sub: window.userId,
      taxId: window.taxId,
      name: 'Mock',
      surname: 'Surname',
      nickname: 'Mockuser',
    } : null;
  }, [token, skipAll]);

  useEffect(() => {
    if (user && window.customerly && !Capacitor.isNative) {
      window.customerly.update({
        email: user.email,
        name: user.name,
        user_id: user.sub,
        attributes: {
          env: process.env.NODE_ENV,
          application: 'patient',
        },
      });
    }
  }, [user]);

  const value = useMemo<AuthContext>(() => ({
    ready: !!token || !!skipAll,
    token,
    user,
    refreshToken: refreshAccessToken,
    getToken: () => token?.accessToken || '',
    logout,
    settingsUrl,
  }), [token, refreshAccessToken, logout, skipAll, user]);

  if (!token && !skipAll) {
    return <Loading/>;
  }

  return (
    <AuthCtx.Provider value={value}>
      {children}
    </AuthCtx.Provider>
  )
}

export class SkipAllError extends Error {}
