import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { DoneIcon, NotificationIcon, RefreshIcon } from '../../utils/icons';
import {
  Badge,
  Box,
  IconButton,
  List, ListItem,
  ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { MoreHoriz, Tab } from '@material-ui/icons';
import { getRoute, RoutesKeys } from '../../routes';
import { useNotifications } from '../../hooks/useNotifications';
import { useReadNotifications } from '../../hooks/useReadNotifications';
import { NotificationItem } from './notification-item';

const useStyles = makeStyles(theme => ({
  widgetPaper: {
    minWidth: 400,
    maxHeight: 600,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '70vh',
      maxWidth: '90vw',
      minWidth: 0,
    },
  },
  widgetList: {
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
  },
}));

export const NotificationMenu: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const { notifications, loading, refetch, subscribe } = useNotifications({
    onlyUnread: false,
    skip: 0,
    limit: 10,
  });
  const { readMany, loading: readManyLoading } = useReadNotifications();

  useEffect(() => {
    subscribe();
  }, [subscribe]);

  const unreadCount = notifications?.reduce((acc, curr) => {
    if (!curr.readAt) {
      acc++;
    }
    return acc;
  }, 0);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsRead = useCallback(() => {
    const unreadIds = notifications?.filter(n => !n.readAt).map(n => n.id);
    if (unreadIds?.length) {
      readMany(unreadIds);
    }
  }, [readMany, notifications]);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="secondary" max={9}>
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: classes.widgetPaper,
        }}
      >
        <div className={classes.widgetList}>
          <Box px={2} pt={1} display="flex" justifyContent="space-between">
            <Typography variant="h6">{t('notifications')}</Typography>
            <IconButton size="small" onClick={(e) => setOptionsAnchorEl(e.currentTarget)}>
              <MoreHoriz />
            </IconButton>
            <Menu
              anchorEl={optionsAnchorEl}
              open={Boolean(optionsAnchorEl)}
              onClose={() => setOptionsAnchorEl(null)}
            >
              {notifications && notifications.length > 0 && (
                <MenuItem
                  button
                  dense
                  disabled={readManyLoading}
                  onClick={markAsRead}
                >
                  <ListItemIcon style={{ minWidth: 36 }}>
                    <DoneIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">{t('mark-all-as-read')}</Typography>
                </MenuItem>
              )}
              <MenuItem
                button
                dense
                component={RouterLink}
                to={getRoute(RoutesKeys.notifications)}
                onClick={() => {
                  setOptionsAnchorEl(null);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  <Tab fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t('see-all')}</Typography>
              </MenuItem>
              <MenuItem
                button
                dense
                onClick={() => refetch()}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  <RefreshIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('refresh')}</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <List disablePadding>
            {loading && (
              [0, 1, 2, 3, 4].map(idx => (
                <NotificationItem divider={idx !== 4} loading={true} key={idx} />
              ))
            )}
            {notifications && notifications?.length > 0 ? notifications?.map((not, i) => (
              <NotificationItem
                divider={i < notifications.length - 1}
                loading={false}
                notification={not}
                key={not.id}
                onClose={handleClose}
              />
            )) : (
              <ListItem>
                <ListItemText primary={t('no-notifications')} />
              </ListItem>
            )}
          </List>
        </div>
      </Popover>
    </>
  )
}
