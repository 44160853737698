import { useQuery } from '@apollo/client';
import {
  NotificationsDocument,
  NotificationsQuery,
  NotificationsQueryVariables,
  OnNewNotificationDocument,
  OnNewNotificationSubscription,
  OnNewNotificationSubscriptionVariables,
} from '../generated/graphql';

import { useCallback, useMemo } from 'react';
import { usePatient } from '../contexts/patient.context';

interface UseNotificationsProps {
  onlyUnread?: boolean;
  skip?: number;
  limit?: number;
  onlyTypes?: string[];
}

export const useNotifications = ({ onlyUnread = false, skip = 0, limit = 10, onlyTypes }: UseNotificationsProps = {}) => {
  const { selectedPatient } = usePatient();
  const filters: NotificationsQueryVariables['filters'] = {};
  if (onlyUnread) {
    filters.readAt = { is_null: true };
  }
  if (onlyTypes) {
    filters.name = { in: onlyTypes };
  }

  const { data, subscribeToMore, ...rest } = useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, {
    variables: {
      filters,
      skip,
      limit,
    },
    context: {
      batch: false,
    },
  });

  const subscribe = useCallback(() => {
    // https://github.com/apollographql/apollo-client/issues/5870
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    subscribeToMore<OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables>({
      document: OnNewNotificationDocument,
      updateQuery: (previousQueryResult, { subscriptionData }) => {
        if (!subscriptionData.data?.onNewNotification) {
          return previousQueryResult;
        }
        const item = subscriptionData.data.onNewNotification;

        return {
          ...previousQueryResult,
          notifications: {
            items: [
              item,
              ...previousQueryResult.notifications.items,
            ],
            paginationInfo: previousQueryResult.notifications.paginationInfo,
          },
        }
      },
    });
  }, [subscribeToMore]);

  const notifications = useMemo(() => {
    return data?.notifications.items.filter(not => {
      const destination = not.destinationProfile;
      if (!destination || !selectedPatient) return true;
      const [role, id] = destination.split(':');
      return role === 'Patient' && id.toString() === selectedPatient.id.toString()
    });
  }, [data, selectedPatient]);

  return useMemo(() => ({
    notifications,
    paginationInfo: data?.notifications.paginationInfo,
    subscribe,
    ...rest,
  }), [
    data,
    rest,
    subscribe,
    notifications,
  ]);
}
