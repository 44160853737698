import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import SearchIcon from '@material-ui/icons/Search';

export const provisionsRoutes: RouteType[] = [
  {
    key: RoutesKeys.provisions,
    component: asyncLoad(() => import('../containers/search-provision/search-provision.container')),
    path: '/cerca-prestazione',
    exact: true,
    config: {
      label: 'Cerca prestazione',
      title: 'Cerca prestazione',
      icon: SearchIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.browseProviderProvisions,
    component: asyncLoad(() => import('../containers/browse-provider-provisions/browse-provider-provisions.container')),
    path: '/sfoglia-prestazioni/:providerId',
    exact: true,
    config: {
      label: 'Sfoglia prestazioni',
      title: 'Sfoglia prestazioni',
      icon: SearchIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
];
