import { memo, useCallback } from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SharedDialog } from '@hu-care/react-ui-store';
import { Layout as HuCareLayout, NavBarProps, useLayout } from '@hu-care/react-layout';
import { NavBar } from './nav-bar';
import BottomBar from './bottom-bar';
import { useInsets, Insets } from '../../contexts/insets';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexDirection: 'column',
      height: '100%',
    },
    content: (props: Insets) => ({
      zIndex: 0,
      paddingLeft: 0,
      paddingRight: 0,
      flexGrow: 1,
      paddingBottom: (props.bottom || 0) + 70,
      display: 'flex',
      flexDirection: 'column',
    }),
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    sharedDialog: {
      '& .MuiSnackbar-anchorOriginBottomLeft': {
        position: 'fixed',
        bottom: '80px',
      },
      zIndex: 20000000000,
    },
  }),
);

export const Layout = memo(({ children }) => {
  const insets = useInsets();
  const classes = useStyles(insets);

  const navBar = useCallback((props: NavBarProps) => (
    <NavBar {...props}/>
  ), []);

  const { containerProps } = useLayout<{ containerProps?: any }>();
  return (
    <HuCareLayout
      showEnvBar={false}
      navBar={navBar}
      mainClassName={classes.content}
      rootClassName={classes.root}
      maxWidth="sm"
      {...containerProps}
      className={clsx(classes.container, containerProps?.className)}
    >
        {children}
        <span className={classes.sharedDialog}>
          <SharedDialog/>
        </span>
        <BottomBar insets={insets}/>
    </HuCareLayout>
  );
});
