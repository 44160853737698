/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  TaxId: any;
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Weekday: any;
};

export type AddressFilterInput = {
  address?: Maybe<StringFieldFilterInput>;
  coordinates?: Maybe<GeolocationFilterInput>;
};

export type AgendaEntry = BaseEntity & {
  __typename?: 'AgendaEntry';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  weekday: Scalars['Weekday'];
  override?: Maybe<Scalars['Date']>;
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  assistantIds: Array<Scalars['String']>;
  visibility: AgendaEntryVisibility;
  agendaWeekId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  professionalId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  clinic: ClinicRoom;
  professional?: Maybe<Professional>;
  assistants: Array<Maybe<Assistant>>;
};

export type AgendaEntryInDay = {
  __typename?: 'AgendaEntryInDay';
  id: Scalars['ID'];
  weekday: Scalars['Weekday'];
  override?: Maybe<Scalars['Date']>;
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  assistantIds: Array<Scalars['String']>;
  visibility: AgendaEntryVisibility;
  day: Scalars['Date'];
  clinicId: Scalars['String'];
  professionalId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  agendaWeekId?: Maybe<Scalars['String']>;
  professional?: Maybe<Professional>;
  assistants: Array<Maybe<Assistant>>;
  clinic: ClinicRoom;
};

export enum AgendaEntryVisibility {
  OnlyAssistants = 'ONLY_ASSISTANTS',
  All = 'ALL',
  OnlyAssisted = 'ONLY_ASSISTED',
  OnlyFreelancer = 'ONLY_FREELANCER'
}

export type AgendaEvent = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: AgendaEventType;
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes: Scalars['String'];
  createdBy: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: ClinicRoom;
  professional: Professional;
  creator: User;
};

export enum AgendaEventType {
  Appointment = 'Appointment',
  Break = 'Break'
}

export type AgendaList = BaseEntity & {
  __typename?: 'AgendaList';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  status: AgendaListStatus;
  from: Scalars['Date'];
  to?: Maybe<Scalars['Date']>;
  openFor: Scalars['Int'];
  professionalId: Scalars['String'];
  weeks: Array<AgendaWeek>;
};

export type AgendaListFilterInput = {
  _or?: Maybe<Array<AgendaListFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  status?: Maybe<AgendaListStatusFieldFilterInput>;
  openFor?: Maybe<IntFieldFilterInput>;
};

export enum AgendaListStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED'
}

export type AgendaListStatusFieldFilterInput = {
  eq?: Maybe<AgendaListStatus>;
  ne?: Maybe<AgendaListStatus>;
  in?: Maybe<Array<AgendaListStatus>>;
  nin?: Maybe<Array<AgendaListStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type AgendaWeek = BaseEntity & {
  __typename?: 'AgendaWeek';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  order: Scalars['Int'];
  agendaListId: Scalars['String'];
  entries: Array<AgendaEntry>;
};

export type Appointment = AgendaEvent & BaseEntity & {
  __typename?: 'Appointment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: AgendaEventType;
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes: Scalars['String'];
  createdBy: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: ClinicRoom;
  professional: Professional;
  creator: User;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['TaxId']>;
  patientProfessionalId?: Maybe<Scalars['String']>;
  provisionId?: Maybe<Scalars['String']>;
  provision?: Maybe<ProfessionalProvision>;
  patientProfessional?: Maybe<PatientProfessional>;
};

export type Assistant = Professional & BaseEntity & {
  __typename?: 'Assistant';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  assistantTermsAndConditions: Scalars['Boolean'];
};

export type Attachment = BaseEntity & {
  __typename?: 'Attachment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  fileSize: Scalars['Int'];
  storeType: StoreType;
  url: Scalars['String'];
};

export type BaseEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type BooleanFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Break = AgendaEvent & BaseEntity & {
  __typename?: 'Break';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: AgendaEventType;
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes: Scalars['String'];
  createdBy: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: ClinicRoom;
  professional: Professional;
  creator: User;
};

export type City = BaseEntity & {
  __typename?: 'City';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  code: Scalars['String'];
  registryCode: Scalars['String'];
  cap: Array<Scalars['String']>;
  isDistrict: Scalars['Boolean'];
  district: District;
  region: Region;
  coordinates?: Maybe<Point>;
};

export type CityFilterInput = {
  _or?: Maybe<Array<CityFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  code?: Maybe<StringFieldFilterInput>;
  registryCode?: Maybe<StringFieldFilterInput>;
  isDistrict?: Maybe<CityIsDistrictFieldFilterInput>;
};

export type CityIsDistrictFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type CityOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  code?: Maybe<Order>;
  registryCode?: Maybe<Order>;
  isDistrict?: Maybe<Order>;
};

export type CityPaginatedResponse = {
  __typename?: 'CityPaginatedResponse';
  items: Array<City>;
  paginationInfo: PaginationInfo;
};

export type Clinic = BaseEntity & {
  __typename?: 'Clinic';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  professionalId: Scalars['String'];
  address: EmbedAddress;
  professional: Professional;
  rooms: Array<ClinicRoom>;
};

export type ClinicFilterInput = {
  _or?: Maybe<Array<ClinicFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
};

export type ClinicOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  phone?: Maybe<Order>;
};

export type ClinicRoom = BaseEntity & {
  __typename?: 'ClinicRoom';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  meta: Scalars['JSON'];
  clinicId: Scalars['String'];
  provisions: Array<ProfessionalProvision>;
  clinic: Clinic;
};

export type ClinicRoomFromDay = {
  __typename?: 'ClinicRoomFromDay';
  room: ClinicRoom;
  fromDay: Scalars['Date'];
};

export type CopyAgendaListInput = {
  from: Scalars['Date'];
  status?: Maybe<AgendaListStatus>;
  openFor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateAgendaEntryInput = {
  weekday: Scalars['Weekday'];
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  visibility: AgendaEntryVisibility;
  clinicId: Scalars['String'];
  assistantIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateAgendaListInput = {
  name: Scalars['String'];
  from: Scalars['Date'];
  openFor: Scalars['Int'];
  status?: Maybe<AgendaListStatus>;
};

export type CreateAppointmentInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  provisionId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
};

export type CreateAttachmentInput = {
  file: Scalars['Upload'];
  destination: Scalars['String'];
};

export type CreateBreakInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type CreateClinicInput = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  rooms?: Maybe<Array<CreateClinicRoomInput>>;
  address: CreateEmbedAddressInput;
};

export type CreateClinicRoomInput = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  provisionIds?: Maybe<Array<Scalars['String']>>;
  meta: Scalars['JSON'];
};

export type CreateCryptedEmbedAddressInput = {
  address: Scalars['String'];
  municipalityId: Scalars['String'];
};

export type CreateEmbedAddressInput = {
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  municipalityId: Scalars['String'];
  coordinates?: Maybe<LatLngInput>;
};

export type CreateEmbedContactInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
};

export type CreateMedicInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer?: Maybe<Scalars['Boolean']>;
  chatEnabled?: Maybe<Scalars['Boolean']>;
  requestsEnabled?: Maybe<Scalars['Boolean']>;
  registerDistrictId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
  termsAndConditions: Scalars['Boolean'];
};

export type CreateOperatorInput = {
  profession?: Maybe<OperatorProfession>;
  termsAndConditions: Scalars['Boolean'];
};

export type CreatePatientInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
};

export type CreatePatientProfessionalInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address: CreateCryptedEmbedAddressInput;
  isPersonalMedic?: Maybe<Scalars['Boolean']>;
};

export type CreateProfessionalProvisionInput = {
  provisionId: Scalars['String'];
  price: Scalars['Float'];
  duration: Scalars['Int'];
};

export type CreateReportInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  attachments?: Maybe<Array<CreateAttachmentInput>>;
  medicName?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['DateTime']>;
};

export type CreateRequestInput = {
  recipientId: Scalars['String'];
  recipientType: RequestRecipientType;
  title?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  type: RequestType;
  private?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
};

export type CreateRequestMessageInput = {
  message: Scalars['String'];
  attachments?: Maybe<Array<CreateAttachmentInput>>;
};

export type CreateUserPatientInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  address?: Maybe<CreateEmbedAddressInput>;
};

export type CryptedEmbedAddress = {
  __typename?: 'CryptedEmbedAddress';
  address: Scalars['String'];
  municipalityName: Scalars['String'];
  districtName: Scalars['String'];
  nationName: Scalars['String'];
  municipalityId?: Maybe<Scalars['String']>;
};

export type CupProvider = {
  __typename?: 'CupProvider';
  id: Scalars['ID'];
  date: Scalars['Date'];
  duration: Scalars['String'];
  price: Scalars['String'];
  address: Scalars['String'];
  addressName: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  trackUrl: Scalars['String'];
};


export type DateDFilterInputType = {
  eq?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  nin?: Maybe<Array<Scalars['Date']>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type DateFieldFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  is_null?: Maybe<Scalars['Boolean']>;
};


export type District = {
  __typename?: 'District';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type EmbedAddress = {
  __typename?: 'EmbedAddress';
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  municipalityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  nationName?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Point>;
  municipalityId?: Maybe<Scalars['String']>;
  municipality?: Maybe<City>;
};

export type EmbedContact = {
  __typename?: 'EmbedContact';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
};

export type FindClinicFilterInput = {
  _or?: Maybe<Array<ClinicFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
  geolocation?: Maybe<GeolocationFilterInput>;
  address?: Maybe<AddressFilterInput>;
};

export type FloatFieldFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type GeolocationFilterInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  distance?: Maybe<Scalars['Int']>;
};

export type IdFieldFilterInput = {
  eq?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  nin?: Maybe<Array<Scalars['ID']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type IntFieldFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Invite = BaseEntity & {
  __typename?: 'Invite';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  type: InviteType;
  inviteRole?: Maybe<Scalars['String']>;
  invitedUserId?: Maybe<Scalars['String']>;
  inviter: Professional;
};

export enum InviteType {
  Assistant = 'ASSISTANT',
  Organization = 'ORGANIZATION',
  Patient = 'PATIENT'
}

export type InviteUserInput = {
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['TaxId']>;
  phone?: Maybe<Scalars['String']>;
};


export type JsonFieldFilterInput = {
  eq?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  in?: Maybe<Array<Scalars['JSON']>>;
  nin?: Maybe<Array<Scalars['JSON']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type LatLngInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type License = BaseEntity & {
  __typename?: 'License';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  transactionType: PaymentType;
  transactionId: Scalars['String'];
  transactionData: Scalars['JSON'];
  code: Scalars['String'];
  licenseType: LicenseType;
  priceId: Scalars['String'];
};

export type LicenseFilterInput = {
  _or?: Maybe<Array<LicenseFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  expiresAt?: Maybe<DateFieldFilterInput>;
  transactionType?: Maybe<PaymentTypeFieldFilterInput>;
  transactionId?: Maybe<StringFieldFilterInput>;
  licenseType?: Maybe<LicenseTypeFilterInput>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeProduct: StripeProduct;
};

export type LicenseTypeFilterInput = {
  _or?: Maybe<Array<LicenseTypeFilterInput>>;
};

export enum MassiveEmailTarget {
  AllPatients = 'ALL_PATIENTS',
  PersonalPatients = 'PERSONAL_PATIENTS',
  FreelancerPatients = 'FREELANCER_PATIENTS',
  Networks = 'NETWORKS'
}

export type Medic = Professional & BaseEntity & {
  __typename?: 'Medic';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer: Scalars['Boolean'];
  contacts?: Maybe<EmbedContact>;
  termsAndConditions: Scalars['Boolean'];
  registerDistrictId?: Maybe<Scalars['String']>;
  chatEnabled: Scalars['Boolean'];
  requestsEnabled: Scalars['Boolean'];
  registerDistrict?: Maybe<City>;
};

export type MedicAgendaFilterInput = {
  _or?: Maybe<Array<AgendaListFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  status?: Maybe<AgendaListStatusFieldFilterInput>;
  openFor?: Maybe<IntFieldFilterInput>;
  from?: Maybe<DateDFilterInputType>;
  to?: Maybe<DateDFilterInputType>;
};

export type MedicAgendaOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  status?: Maybe<Order>;
  from?: Maybe<Order>;
  to?: Maybe<Order>;
  openFor?: Maybe<Order>;
};

export enum MedicType {
  Mmg = 'MMG',
  Pls = 'PLS'
}

export type Mutation = {
  __typename?: 'Mutation';
  markNotificationAsRead: Notification;
  markManyNotificationsAsRead: Array<Notification>;
  mockNotification: Notification;
  deleteMedicProfile?: Maybe<Scalars['ID']>;
  deleteOperatorProfile?: Maybe<Scalars['ID']>;
  deleteAssistantProfile?: Maybe<Scalars['ID']>;
  assignPersonalMedic?: Maybe<Medic>;
  updatePatient: Patient;
  createUserPatient: PatientUser;
  deleteUserPatient: Scalars['ID'];
  grantUserAccessToPatient: PatientUser;
  revokeUserAccessToPatient: Scalars['ID'];
  createMedic: Medic;
  updateMedic: Medic;
  requestConnection: Network;
  updateConnection: Network;
  deleteConnection: Scalars['ID'];
  updateAssistant: Assistant;
  uploadPrivacyPolicy: Attachment;
  createPatientProfessionalAsPatient: PatientProfessional;
  createPatientProfessional: PatientProfessional;
  updatePatientProfessionalStatus: PatientProfessional;
  updatePatientProfessional: PatientProfessional;
  archivePatientProfessional: PatientProfessional;
  sendMail: Scalars['Boolean'];
  sendMassiveEmail: Scalars['Boolean'];
  inviteAssistant: Invite;
  inviteToOrganization: Invite;
  invitePatient: Invite;
  acceptAssistantInvite: Professional;
  acceptOrganizationInvite: OrganizationProfessional;
  deleteInvite: Scalars['Boolean'];
  createOperator: Operator;
  updateOperator: Operator;
  removeProfessionalFromOrganization: Scalars['ID'];
  sharePatientWithProfessional: Scalars['Boolean'];
  revokeSharePatientWithProfessional: Scalars['Boolean'];
  createProfessionalProvision: ProfessionalProvision;
  /** @deprecated use createProfessionalProvision */
  createMedicProvision: ProfessionalProvision;
  updateProfessionalProvision: ProfessionalProvision;
  deleteProfessionalProvision: Scalars['ID'];
  createAgendaList: AgendaList;
  updateAgendaList: AgendaList;
  deleteAgendaList: Scalars['ID'];
  copyAgendaList: AgendaList;
  createAgendaWeek: AgendaWeek;
  updateAgendaWeek: AgendaWeek;
  deleteAgendaWeek: Scalars['ID'];
  createManyAgendaEntry: Array<AgendaEntry>;
  createAgendaEntry: AgendaEntry;
  updateAgendaEntry: AgendaEntry;
  deleteAgendaEntry: Scalars['ID'];
  assignMedicToEntry: AgendaEntry;
  dissociateFromEntry: Scalars['ID'];
  overrideDay: Array<AgendaEntryInDay>;
  resetDay: Array<AgendaEntryInDay>;
  createOverrideEntry: AgendaEntryInDay;
  createClinic: Clinic;
  updateClinic: Clinic;
  deleteClinic: Scalars['ID'];
  createClinicRoom: ClinicRoom;
  updateClinicRoom: ClinicRoom;
  deleteClinicRoom: Scalars['ID'];
  createAppointment: Appointment;
  updateAppointment: Appointment;
  deleteAppointment: Scalars['ID'];
  createAppointmentAsPatient: Appointment;
  deleteAppointmentAsPatient: Scalars['ID'];
  createBreak: Break;
  updateBreak: Break;
  deleteBreak: Scalars['ID'];
  createRequestAsProfessional: Request;
  createRequestAsPatient: Request;
  resolveRequest: Request;
  answerRequestAsProfessional: RequestMessage;
  markRequestMessageAsReadAsProfessional: RequestMessage;
  deleteRequest: Scalars['ID'];
  addSpecializationToMedic: Specialization;
  removeSpecializationFromMedic: Scalars['ID'];
  getChatAccessToken: Scalars['String'];
  addChatUser: Scalars['Boolean'];
  createChat: Scalars['String'];
  createReportAsProfessional: Report;
  createReportAsPatient: Report;
  updateReportAsProfessional: Report;
  deleteReportAsProfessional: Scalars['ID'];
  shareReport: PatientProfessional;
  revokeReportShare: Scalars['ID'];
  deleteReport: Scalars['ID'];
};


export type MutationMarkNotificationAsReadArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
};


export type MutationMarkManyNotificationsAsReadArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  ids: Array<Scalars['String']>;
};


export type MutationAssignPersonalMedicArgs = {
  medicId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
};


export type MutationUpdatePatientArgs = {
  patient: UpdatePatientInput;
  patientId: Scalars['String'];
};


export type MutationCreateUserPatientArgs = {
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<CreateUserPatientInput>;
  termsAndConditions: Scalars['Boolean'];
  type?: Maybe<PatientType>;
};


export type MutationDeleteUserPatientArgs = {
  id: Scalars['String'];
};


export type MutationGrantUserAccessToPatientArgs = {
  type: PatientType;
  userId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationRevokeUserAccessToPatientArgs = {
  userId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationCreateMedicArgs = {
  medic: CreateMedicInput;
};


export type MutationUpdateMedicArgs = {
  medic: UpdateMedicInput;
};


export type MutationRequestConnectionArgs = {
  requestId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateConnectionArgs = {
  status: NetworkStatus;
  id: Scalars['String'];
};


export type MutationDeleteConnectionArgs = {
  id: Scalars['String'];
};


export type MutationUpdateAssistantArgs = {
  assistant: UpdateAssistantInput;
};


export type MutationUploadPrivacyPolicyArgs = {
  link?: Maybe<Scalars['String']>;
  file?: Maybe<CreateAttachmentInput>;
  medicId: Scalars['String'];
};


export type MutationCreatePatientProfessionalAsPatientArgs = {
  professionalId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationCreatePatientProfessionalArgs = {
  patientProfessional: CreatePatientProfessionalInput;
  patient: CreatePatientInput;
  medicId: Scalars['String'];
};


export type MutationUpdatePatientProfessionalStatusArgs = {
  patientId?: Maybe<Scalars['String']>;
  medicId?: Maybe<Scalars['String']>;
  status: UpdatablePatientProfessionalStatus;
  id: Scalars['String'];
};


export type MutationUpdatePatientProfessionalArgs = {
  patient: UpdatePatientProfessionalInput;
  id: Scalars['String'];
};


export type MutationArchivePatientProfessionalArgs = {
  id: Scalars['String'];
};


export type MutationSendMailArgs = {
  attachments?: Maybe<Array<Scalars['Upload']>>;
  body: Scalars['String'];
  title: Scalars['String'];
  to: Array<Scalars['String']>;
  medicId: Scalars['String'];
};


export type MutationSendMassiveEmailArgs = {
  attachments?: Maybe<Array<Scalars['Upload']>>;
  body: Scalars['String'];
  title: Scalars['String'];
  target: MassiveEmailTarget;
  medicId: Scalars['String'];
};


export type MutationInviteAssistantArgs = {
  user: InviteUserInput;
  medicId: Scalars['String'];
};


export type MutationInviteToOrganizationArgs = {
  user: InviteUserInput;
  role: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationInvitePatientArgs = {
  patientProfessionalId: Scalars['String'];
  email: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationAcceptAssistantInviteArgs = {
  assistantTermsAndConditions: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationAcceptOrganizationInviteArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['String'];
};


export type MutationCreateOperatorArgs = {
  operator: CreateOperatorInput;
};


export type MutationUpdateOperatorArgs = {
  operator: UpdateOperatorInput;
};


export type MutationRemoveProfessionalFromOrganizationArgs = {
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationSharePatientWithProfessionalArgs = {
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationRevokeSharePatientWithProfessionalArgs = {
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationCreateProfessionalProvisionArgs = {
  provision: CreateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationCreateMedicProvisionArgs = {
  provision: CreateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationUpdateProfessionalProvisionArgs = {
  provision: UpdateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationDeleteProfessionalProvisionArgs = {
  id: Scalars['String'];
};


export type MutationCreateAgendaListArgs = {
  numberOfWeeks?: Maybe<Scalars['Int']>;
  agendaList: CreateAgendaListInput;
  medicId?: Maybe<Scalars['String']>;
};


export type MutationUpdateAgendaListArgs = {
  agendaList: UpdateAgendaListInput;
  id: Scalars['String'];
};


export type MutationDeleteAgendaListArgs = {
  id: Scalars['String'];
};


export type MutationCopyAgendaListArgs = {
  agendaList: CopyAgendaListInput;
  id: Scalars['String'];
};


export type MutationCreateAgendaWeekArgs = {
  order: Scalars['Float'];
  agendaId: Scalars['String'];
};


export type MutationUpdateAgendaWeekArgs = {
  order: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationDeleteAgendaWeekArgs = {
  id: Scalars['String'];
};


export type MutationCreateManyAgendaEntryArgs = {
  agendaEntries: Array<CreateAgendaEntryInput>;
  weekId: Scalars['String'];
};


export type MutationCreateAgendaEntryArgs = {
  agendaEntry: CreateAgendaEntryInput;
  weekId: Scalars['String'];
};


export type MutationUpdateAgendaEntryArgs = {
  agendaEntry: UpdateAgendaEntryInput;
  id: Scalars['String'];
};


export type MutationDeleteAgendaEntryArgs = {
  id: Scalars['String'];
};


export type MutationAssignMedicToEntryArgs = {
  medicId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationDissociateFromEntryArgs = {
  id: Scalars['String'];
};


export type MutationOverrideDayArgs = {
  date: Scalars['Date'];
  medicId: Scalars['String'];
};


export type MutationResetDayArgs = {
  date: Scalars['Date'];
  medicId: Scalars['String'];
};


export type MutationCreateOverrideEntryArgs = {
  date: Scalars['Date'];
  agendaEntry: CreateAgendaEntryInput;
  medicId: Scalars['String'];
};


export type MutationCreateClinicArgs = {
  clinic: CreateClinicInput;
  medicId: Scalars['String'];
};


export type MutationUpdateClinicArgs = {
  clinic: UpdateClinicInput;
  id: Scalars['String'];
};


export type MutationDeleteClinicArgs = {
  id: Scalars['String'];
};


export type MutationCreateClinicRoomArgs = {
  room: CreateClinicRoomInput;
  clinicId: Scalars['String'];
};


export type MutationUpdateClinicRoomArgs = {
  room: UpdateClinicRoomInput;
  id: Scalars['String'];
};


export type MutationDeleteClinicRoomArgs = {
  id: Scalars['String'];
};


export type MutationCreateAppointmentArgs = {
  appointment: CreateAppointmentInput;
  medicId: Scalars['String'];
};


export type MutationUpdateAppointmentArgs = {
  appointment: UpdateAppointmentInput;
  id: Scalars['String'];
};


export type MutationDeleteAppointmentArgs = {
  id: Scalars['String'];
};


export type MutationCreateAppointmentAsPatientArgs = {
  appointment: CreateAppointmentInput;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationDeleteAppointmentAsPatientArgs = {
  id: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationCreateBreakArgs = {
  break: CreateBreakInput;
  medicId: Scalars['String'];
};


export type MutationUpdateBreakArgs = {
  break: UpdateBreakInput;
  id: Scalars['String'];
};


export type MutationDeleteBreakArgs = {
  id: Scalars['String'];
};


export type MutationCreateRequestAsProfessionalArgs = {
  medicId: Scalars['String'];
  request: CreateRequestInput;
};


export type MutationCreateRequestAsPatientArgs = {
  patientId: Scalars['String'];
  request: CreateRequestInput;
};


export type MutationResolveRequestArgs = {
  result: RequestResult;
  id: Scalars['String'];
};


export type MutationAnswerRequestAsProfessionalArgs = {
  message: CreateRequestMessageInput;
  medicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationMarkRequestMessageAsReadAsProfessionalArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  medicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteRequestArgs = {
  id: Scalars['String'];
};


export type MutationAddSpecializationToMedicArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSpecializationFromMedicArgs = {
  id: Scalars['String'];
};


export type MutationAddChatUserArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateChatArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateReportAsProfessionalArgs = {
  report: CreateReportInput;
  patientProfessionalId: Scalars['String'];
};


export type MutationCreateReportAsPatientArgs = {
  patientProfessionalId?: Maybe<Scalars['String']>;
  report: CreateReportInput;
  patientId: Scalars['String'];
};


export type MutationUpdateReportAsProfessionalArgs = {
  report: UpdateReportInput;
  id: Scalars['String'];
};


export type MutationDeleteReportAsProfessionalArgs = {
  id: Scalars['String'];
};


export type MutationShareReportArgs = {
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRevokeReportShareArgs = {
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['String'];
};

export type Network = BaseEntity & {
  __typename?: 'Network';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: NetworkStatus;
  canImpersonate: Scalars['Int'];
  ownerId: Scalars['String'];
  inverseId: Scalars['String'];
  inverse: Professional;
};

export enum NetworkStatus {
  Requested = 'REQUESTED',
  Approved = 'APPROVED',
  Received = 'RECEIVED',
  Blacklisted = 'BLACKLISTED'
}

export type Notification = BaseEntity & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  destinationProfile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  readAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
};

export type NotificationFilterInput = {
  _or?: Maybe<Array<NotificationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  destinationProfile?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  readAt?: Maybe<DateFieldFilterInput>;
};

export type NotificationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  userId?: Maybe<Order>;
  destinationProfile?: Maybe<Order>;
  name?: Maybe<Order>;
  readAt?: Maybe<Order>;
};

export type NotificationPaginatedResponse = {
  __typename?: 'NotificationPaginatedResponse';
  items: Array<Notification>;
  paginationInfo: PaginationInfo;
};

export type Operator = Professional & BaseEntity & {
  __typename?: 'Operator';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  profession?: Maybe<OperatorProfession>;
  chatEnabled: Scalars['Boolean'];
  requestsEnabled: Scalars['Boolean'];
  contacts?: Maybe<EmbedContact>;
  termsAndConditions: Scalars['Boolean'];
};

export enum OperatorProfession {
  Nurse = 'NURSE',
  Psychologist = 'PSYCHOLOGIST',
  Physiotherapist = 'PHYSIOTHERAPIST',
  Dietician = 'DIETICIAN',
  Podiatrist = 'PODIATRIST',
  Pharmacist = 'PHARMACIST'
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = BaseEntity & {
  __typename?: 'Organization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  serviceAccount: OrganizationSa;
  professionals: Array<OrganizationProfessional>;
};

export type OrganizationFilterInput = {
  _or?: Maybe<Array<OrganizationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  serviceAccount?: Maybe<OrganizationSaFilterInput>;
};

export type OrganizationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export type OrganizationProfessional = BaseEntity & {
  __typename?: 'OrganizationProfessional';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId: Scalars['String'];
  role: OrganizationRole;
  organization: Organization;
  professional: Professional;
};

export type OrganizationProfessionalFilterInput = {
  _or?: Maybe<Array<OrganizationProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
};

export enum OrganizationRole {
  Admin = 'ADMIN',
  Medic = 'MEDIC'
}

export type OrganizationSa = Professional & BaseEntity & {
  __typename?: 'OrganizationSa';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  contacts?: Maybe<EmbedContact>;
  managedOrganization: Organization;
};

export type OrganizationSaFilterInput = {
  _or?: Maybe<Array<OrganizationSaFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  organizations?: Maybe<OrganizationProfessionalFilterInput>;
  paymentMethods?: Maybe<PaymentMethodFilterInput>;
  licenses?: Maybe<LicenseFilterInput>;
  specializations?: Maybe<SpecializationFilterInput>;
  provisions?: Maybe<ProfessionalProvisionFilterInput>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasMore: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type Patient = BaseEntity & {
  __typename?: 'Patient';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  createdBy: Scalars['String'];
  fullName: Scalars['String'];
  personalMedicId?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  personalMedic?: Maybe<Medic>;
  patientProfessionals: Array<PatientProfessional>;
  users: Array<PatientUser>;
};

export type PatientFilterInput = {
  _or?: Maybe<Array<PatientFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  taxId?: Maybe<TaxIdScalarFieldFilterInput>;
  birthDay?: Maybe<DateFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
};

export type PatientOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  surname?: Maybe<Order>;
  taxId?: Maybe<Order>;
  birthDay?: Maybe<Order>;
  createdBy?: Maybe<Order>;
};

export type PatientPaginatedResponse = {
  __typename?: 'PatientPaginatedResponse';
  items: Array<Patient>;
  paginationInfo: PaginationInfo;
};

export type PatientProfessional = BaseEntity & {
  __typename?: 'PatientProfessional';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: PatientProfessionalStatus;
  createdBy: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CryptedEmbedAddress>;
  professionalId?: Maybe<Scalars['String']>;
  organizationProfessionalId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  reports: Array<Report>;
  fullName: Scalars['String'];
  patient: Patient;
  upcomingAppointments: Array<Appointment>;
  professional?: Maybe<Professional>;
};


export type PatientProfessionalUpcomingAppointmentsArgs = {
  daysLimit?: Maybe<Scalars['Int']>;
};

export type PatientProfessionalFilterInput = {
  _or?: Maybe<Array<PatientProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  status?: Maybe<PatientProfessionalStatusFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  email?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  reports?: Maybe<ReportFilterInput>;
};

export type PatientProfessionalOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  status?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  email?: Maybe<Order>;
  phone?: Maybe<Order>;
};

export type PatientProfessionalPaginatedResponse = {
  __typename?: 'PatientProfessionalPaginatedResponse';
  items: Array<PatientProfessional>;
  paginationInfo: PaginationInfo;
};

export enum PatientProfessionalStatus {
  RequestedByMedic = 'REQUESTED_BY_MEDIC',
  RequestedByPatient = 'REQUESTED_BY_PATIENT',
  Inactive = 'INACTIVE',
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Archived = 'ARCHIVED',
  Hidden = 'HIDDEN'
}

export type PatientProfessionalStatusFieldFilterInput = {
  eq?: Maybe<PatientProfessionalStatus>;
  ne?: Maybe<PatientProfessionalStatus>;
  in?: Maybe<Array<PatientProfessionalStatus>>;
  nin?: Maybe<Array<PatientProfessionalStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export enum PatientType {
  Owner = 'OWNER',
  FamilyMember = 'FAMILY_MEMBER',
  Tutor = 'TUTOR'
}

export type PatientTypeFieldFilterInput = {
  eq?: Maybe<PatientType>;
  ne?: Maybe<PatientType>;
  in?: Maybe<Array<PatientType>>;
  nin?: Maybe<Array<PatientType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type PatientUser = BaseEntity & {
  __typename?: 'PatientUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  type: PatientType;
  termsAndConditions: Scalars['Boolean'];
  patientId: Scalars['String'];
  patient: Patient;
  user: User;
};

export type PatientUserFilterInput = {
  _or?: Maybe<Array<PatientUserFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  type?: Maybe<PatientTypeFieldFilterInput>;
  patient?: Maybe<PatientFilterInput>;
};

export type PatientUserOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  userId?: Maybe<Order>;
  type?: Maybe<Order>;
};

export type PaymentMethod = BaseEntity & {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: PaymentType;
  data: Scalars['JSON'];
};

export type PaymentMethodFilterInput = {
  _or?: Maybe<Array<PaymentMethodFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  type?: Maybe<PaymentTypeFieldFilterInput>;
  data?: Maybe<JsonFieldFilterInput>;
};

export enum PaymentType {
  Stripe = 'STRIPE'
}

export type PaymentTypeFieldFilterInput = {
  eq?: Maybe<PaymentType>;
  ne?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  nin?: Maybe<Array<PaymentType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Professional = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
};

export type ProfessionalFilterInput = {
  _or?: Maybe<Array<ProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  organizations?: Maybe<OrganizationProfessionalFilterInput>;
  paymentMethods?: Maybe<PaymentMethodFilterInput>;
  licenses?: Maybe<LicenseFilterInput>;
  specializations?: Maybe<SpecializationFilterInput>;
  provisions?: Maybe<ProfessionalProvisionFilterInput>;
};

export type ProfessionalOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  role?: Maybe<Order>;
  userId?: Maybe<Order>;
  name?: Maybe<Order>;
  surname?: Maybe<Order>;
};

export type ProfessionalPaginatedResponse = {
  __typename?: 'ProfessionalPaginatedResponse';
  items: Array<Professional>;
  paginationInfo: PaginationInfo;
};

export type ProfessionalProvision = BaseEntity & {
  __typename?: 'ProfessionalProvision';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId: Scalars['String'];
  provisionId: Scalars['String'];
  price: Scalars['Float'];
  duration: Scalars['Int'];
  mandatory: Scalars['Boolean'];
  provision: Provision;
};

export type ProfessionalProvisionFilterInput = {
  _or?: Maybe<Array<ProfessionalProvisionFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  professionalId?: Maybe<StringFieldFilterInput>;
  provisionId?: Maybe<StringFieldFilterInput>;
  price?: Maybe<FloatFieldFilterInput>;
  duration?: Maybe<IntFieldFilterInput>;
  mandatory?: Maybe<BooleanFieldFilterInput>;
  provision?: Maybe<ProvisionFilterInput>;
};

export enum ProfessionalRole {
  Medic = 'Medic',
  Assistant = 'Assistant',
  Operator = 'Operator',
  OrganizationSa = 'OrganizationSa'
}

export type ProfessionalRoleFieldFilterInput = {
  eq?: Maybe<ProfessionalRole>;
  ne?: Maybe<ProfessionalRole>;
  in?: Maybe<Array<ProfessionalRole>>;
  nin?: Maybe<Array<ProfessionalRole>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Provision = BaseEntity & {
  __typename?: 'Provision';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  defaultPrice: Scalars['Float'];
  defaultDuration: Scalars['Int'];
  code: Scalars['String'];
  mandatory: Scalars['Boolean'];
};

export type ProvisionFilterInput = {
  _or?: Maybe<Array<ProvisionFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  code?: Maybe<StringFieldFilterInput>;
};

export type ProvisionOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  code?: Maybe<Order>;
};

export type ProvisionPaginatedResponse = {
  __typename?: 'ProvisionPaginatedResponse';
  items: Array<Provision>;
  paginationInfo: PaginationInfo;
};

export type PublicPatient = {
  __typename?: 'PublicPatient';
  taxId: Scalars['TaxId'];
};

export type Query = {
  __typename?: 'Query';
  notifications: NotificationPaginatedResponse;
  notification: Notification;
  whoami: User;
  patientProfiles?: Maybe<Array<PatientUser>>;
  patientToAssociate?: Maybe<Patient>;
  medicProfile?: Maybe<Medic>;
  assistantProfile?: Maybe<Assistant>;
  operatorProfile?: Maybe<Operator>;
  patients: PatientPaginatedResponse;
  patient: Patient;
  searchPatientByTaxId?: Maybe<SearchPatientUnion>;
  patientUsers: Array<PatientUser>;
  patientUser: PatientUser;
  network: Network;
  impersonateMedic: Medic;
  professionals: ProfessionalPaginatedResponse;
  professional: Professional;
  professionalsByEmail: Array<Professional>;
  patientProfessional: PatientProfessional;
  searchPatientProfessionals: PatientProfessionalPaginatedResponse;
  patientProfessionals: PatientProfessionalPaginatedResponse;
  cities: CityPaginatedResponse;
  city: City;
  invite: Invite;
  pendingInvites: Array<Invite>;
  sentInvites: Array<Invite>;
  organizations: Array<Organization>;
  organization: Organization;
  managedOrganizations: Array<OrganizationProfessional>;
  licenseTypes: Array<LicenseType>;
  licenseType: LicenseType;
  provisions: ProvisionPaginatedResponse;
  provision: Provision;
  defaultProvisions: Array<Provision>;
  cupProviders: Array<CupProvider>;
  agendaList: AgendaList;
  agendaLists: Array<AgendaList>;
  timeslots: Array<TimeSlot>;
  clinicRoomByProvision: Array<ClinicRoomFromDay>;
  agendaEntriesInDays: Array<AgendaEntryInDay>;
  agendaEntryByDay: Array<AgendaEntryInDay>;
  clinics: Array<Clinic>;
  clinic: Clinic;
  appointment: Appointment;
  agendaEvent: AgendaEvent;
  agendaEventsByDay: Array<AgendaEvent>;
  agendaEventsInDays: Array<AgendaEvent>;
  request: Request;
  sentRequestsAsProfessional: RequestPaginatedResponse;
  receivedRequestsAsProfessional: RequestPaginatedResponse;
  sentRequestsAsPatient: RequestPaginatedResponse;
  specializations: Array<Specialization>;
  specialization: Specialization;
  report: Report;
  patientReports: ReportsPaginatedResponse;
  professionalReport: Report;
  professionalReports: ReportsPaginatedResponse;
};


export type QueryNotificationsArgs = {
  filter?: Maybe<NotificationFilterInput>;
  order?: Maybe<NotificationOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryPatientsArgs = {
  filter?: Maybe<PatientFilterInput>;
  order?: Maybe<PatientOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QuerySearchPatientByTaxIdArgs = {
  taxId: Scalars['TaxId'];
};


export type QueryPatientUsersArgs = {
  filter?: Maybe<PatientUserFilterInput>;
  order?: Maybe<PatientUserOrderInput>;
};


export type QueryPatientUserArgs = {
  id: Scalars['ID'];
};


export type QueryNetworkArgs = {
  id: Scalars['ID'];
};


export type QueryImpersonateMedicArgs = {
  id: Scalars['String'];
};


export type QueryProfessionalsArgs = {
  filter?: Maybe<ProfessionalFilterInput>;
  order?: Maybe<ProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProfessionalArgs = {
  id: Scalars['ID'];
};


export type QueryProfessionalsByEmailArgs = {
  email: Scalars['String'];
};


export type QueryPatientProfessionalArgs = {
  id: Scalars['ID'];
};


export type QuerySearchPatientProfessionalsArgs = {
  medicId: Scalars['String'];
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientSearch: Scalars['String'];
};


export type QueryPatientProfessionalsArgs = {
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryCitiesArgs = {
  filter?: Maybe<CityFilterInput>;
  order?: Maybe<CityOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCityArgs = {
  id: Scalars['ID'];
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
};


export type QuerySentInvitesArgs = {
  medicId: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  filter?: Maybe<OrganizationFilterInput>;
  order?: Maybe<OrganizationOrderInput>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseTypeArgs = {
  code: Scalars['String'];
};


export type QueryProvisionsArgs = {
  filter?: Maybe<ProvisionFilterInput>;
  order?: Maybe<ProvisionOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProvisionArgs = {
  id: Scalars['ID'];
};


export type QueryCupProvidersArgs = {
  coordinates: LatLngInput;
  provisionId: Scalars['String'];
};


export type QueryAgendaListArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaListsArgs = {
  filter?: Maybe<MedicAgendaFilterInput>;
  order?: Maybe<MedicAgendaOrderInput>;
  medicId: Scalars['String'];
};


export type QueryTimeslotsArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  provisionId: Scalars['String'];
};


export type QueryClinicRoomByProvisionArgs = {
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
};


export type QueryAgendaEntriesInDaysArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEntryByDayArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  day: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryClinicsArgs = {
  filter?: Maybe<FindClinicFilterInput>;
  order?: Maybe<ClinicOrderInput>;
};


export type QueryClinicArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaEventArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaEventsByDayArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  day: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEventsInDaysArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryRequestArgs = {
  id: Scalars['ID'];
};


export type QuerySentRequestsAsProfessionalArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryReceivedRequestsAsProfessionalArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QuerySentRequestsAsPatientArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QuerySpecializationsArgs = {
  filter?: Maybe<SpecializationFilterInput>;
  order?: Maybe<SpecializationOrderInput>;
};


export type QuerySpecializationArgs = {
  id: Scalars['ID'];
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryPatientReportsArgs = {
  filter?: Maybe<ReportFilterInput>;
  order?: Maybe<ReportOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QueryProfessionalReportArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type QueryProfessionalReportsArgs = {
  order?: Maybe<ReportOrderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Report = BaseEntity & {
  __typename?: 'Report';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  medicName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  createdBy: Scalars['String'];
  reportDate: Scalars['DateTime'];
  sharedWith: Array<PatientProfessional>;
  creator: User;
  patient: Patient;
  attachments?: Maybe<Array<Attachment>>;
  professional?: Maybe<Professional>;
};

export type ReportFilterInput = {
  _or?: Maybe<Array<ReportFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  medicName?: Maybe<StringFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  description?: Maybe<StringFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  reportDate?: Maybe<DateFieldFilterInput>;
};

export type ReportOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  medicName?: Maybe<Order>;
  title?: Maybe<Order>;
  description?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  reportDate?: Maybe<Order>;
};

export type ReportsPaginatedResponse = {
  __typename?: 'ReportsPaginatedResponse';
  items: Array<Report>;
  paginationInfo: PaginationInfo;
};

export type Request = BaseEntity & {
  __typename?: 'Request';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  result?: Maybe<RequestResult>;
  createdBy: Scalars['String'];
  private: Scalars['Boolean'];
  type: RequestType;
  senderPatientId?: Maybe<Scalars['String']>;
  recipientPatientId?: Maybe<Scalars['String']>;
  messages: Array<RequestMessage>;
  creator: User;
  recipient: RequestAuthor;
  sender: RequestAuthor;
  recipientPatient?: Maybe<Patient>;
  senderPatient?: Maybe<Patient>;
};

export type RequestAuthor = Medic | Operator | Patient | OrganizationSa;

export type RequestFilterInput = {
  _or?: Maybe<Array<RequestFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  result?: Maybe<RequestResultFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  type?: Maybe<RequestTypeFieldFilterInput>;
  messages?: Maybe<RequestMessageFilterInput>;
};

export type RequestMessage = BaseEntity & {
  __typename?: 'RequestMessage';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  patientId?: Maybe<Scalars['String']>;
  author?: Maybe<RequestAuthor>;
  creator: User;
  attachments?: Maybe<Array<Attachment>>;
};

export type RequestMessageFilterInput = {
  _or?: Maybe<Array<RequestMessageFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  message?: Maybe<StringFieldFilterInput>;
  readAt?: Maybe<DateFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
};

export type RequestOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  title?: Maybe<Order>;
  result?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  type?: Maybe<Order>;
};

export type RequestPaginatedResponse = {
  __typename?: 'RequestPaginatedResponse';
  items: Array<Request>;
  paginationInfo: PaginationInfo;
};

export enum RequestRecipientType {
  Patient = 'Patient',
  Professional = 'Professional'
}

export enum RequestResult {
  Ok = 'OK',
  Ko = 'KO'
}

export type RequestResultFieldFilterInput = {
  eq?: Maybe<RequestResult>;
  ne?: Maybe<RequestResult>;
  in?: Maybe<Array<RequestResult>>;
  nin?: Maybe<Array<RequestResult>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export enum RequestType {
  HealthAdvice = 'HEALTH_ADVICE',
  DrugsExams = 'DRUGS_EXAMS',
  Informations = 'INFORMATIONS',
  MedicRequest = 'MEDIC_REQUEST'
}

export type RequestTypeFieldFilterInput = {
  eq?: Maybe<RequestType>;
  ne?: Maybe<RequestType>;
  in?: Maybe<Array<RequestType>>;
  nin?: Maybe<Array<RequestType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type SearchPatientUnion = PublicPatient | Patient;

export type Specialization = BaseEntity & {
  __typename?: 'Specialization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type SpecializationFilterInput = {
  _or?: Maybe<Array<SpecializationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
};

export type SpecializationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export enum StoreType {
  ScwBucket = 'SCW_BUCKET',
  External = 'EXTERNAL'
}

export type StringFieldFilterInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  id: Scalars['String'];
  productId: Scalars['String'];
  amount: Scalars['Float'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  code: Scalars['String'];
  id: Scalars['String'];
  monthlyId: Scalars['String'];
  yearlyId: Scalars['String'];
  monthly: StripePrice;
  yearly: StripePrice;
};

export type Subscription = {
  __typename?: 'Subscription';
  onNewNotification: Notification;
};


export type TaxIdScalarFieldFilterInput = {
  eq?: Maybe<Scalars['TaxId']>;
  ne?: Maybe<Scalars['TaxId']>;
  in?: Maybe<Array<Scalars['TaxId']>>;
  nin?: Maybe<Array<Scalars['TaxId']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};


export type TimeSlot = {
  __typename?: 'TimeSlot';
  day: Scalars['Date'];
  slots: Array<Scalars['Time']>;
};

export enum UpdatablePatientProfessionalStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export type UpdateAgendaEntryInput = {
  weekday?: Maybe<Scalars['Weekday']>;
  disabled?: Maybe<Scalars['Boolean']>;
  from?: Maybe<Scalars['Time']>;
  to?: Maybe<Scalars['Time']>;
  informers?: Maybe<Scalars['Int']>;
  visibility?: Maybe<AgendaEntryVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  assistantIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateAgendaListInput = {
  name?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  openFor?: Maybe<Scalars['Int']>;
  status?: Maybe<AgendaListStatus>;
  updatePreviousList?: Maybe<Scalars['Boolean']>;
};

export type UpdateAppointmentInput = {
  notes?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
};

export type UpdateAssistantInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type UpdateBreakInput = {
  notes?: Maybe<Scalars['String']>;
};

export type UpdateClinicInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
};

export type UpdateClinicRoomInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provisionIds?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
};

export type UpdateMedicInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer?: Maybe<Scalars['Boolean']>;
  chatEnabled?: Maybe<Scalars['Boolean']>;
  requestsEnabled?: Maybe<Scalars['Boolean']>;
  registerDistrictId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
};

export type UpdateOperatorInput = {
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  profession?: Maybe<OperatorProfession>;
  chatEnabled?: Maybe<Scalars['Boolean']>;
  requestsEnabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
};

export type UpdatePatientInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
  address?: Maybe<CreateEmbedAddressInput>;
};

export type UpdatePatientProfessionalInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CreateCryptedEmbedAddressInput>;
};

export type UpdateProfessionalProvisionInput = {
  price?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
};

export type UpdateReportInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
  medicName?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['DateTime']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  role: Scalars['String'];
  traits: UserTraits;
  verifiableAddresses: Array<UserEmailAddress>;
};

export type UserEmailAddress = {
  __typename?: 'UserEmailAddress';
  id: Scalars['ID'];
  value: Scalars['String'];
  verified: Scalars['Boolean'];
  via: Scalars['String'];
  status: Scalars['String'];
  verifiedAt?: Maybe<Scalars['String']>;
};

export type UserTraits = {
  __typename?: 'UserTraits';
  email: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
};


export type CityDataFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'registryCode' | 'name'>
  & { region: (
    { __typename?: 'Region' }
    & Pick<Region, 'name' | 'code'>
  ), district: (
    { __typename?: 'District' }
    & Pick<District, 'name' | 'code'>
  ) }
);

export type EmbedAddressDataFragment = (
  { __typename?: 'EmbedAddress' }
  & Pick<EmbedAddress, 'municipalityName' | 'districtName' | 'address' | 'location' | 'cap' | 'municipalityId'>
  & { municipality?: Maybe<(
    { __typename?: 'City' }
    & CityDataFragment
  )> }
);

export type AgendaEntryBasicDataFragment = (
  { __typename?: 'AgendaEntry' }
  & Pick<AgendaEntry, 'id' | 'disabled' | 'weekday' | 'override' | 'informers' | 'assistantIds' | 'visibility' | 'agendaWeekId' | 'from' | 'to' | 'clinicId'>
);

export type AgendaEntryDataFragment = (
  { __typename?: 'AgendaEntry' }
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
  & AgendaEntryBasicDataFragment
);

export type AgendaEntryInDayDataFragment = (
  { __typename?: 'AgendaEntryInDay' }
  & Pick<AgendaEntryInDay, 'day' | 'id' | 'disabled' | 'weekday' | 'override' | 'informers' | 'assistantIds' | 'visibility' | 'agendaWeekId' | 'from' | 'to' | 'clinicId'>
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
);

export type AgendaWeekBasicDataFragment = (
  { __typename?: 'AgendaWeek' }
  & Pick<AgendaWeek, 'id' | 'order'>
);

export type AgendaWeekDataFragment = (
  { __typename?: 'AgendaWeek' }
  & { entries: Array<(
    { __typename?: 'AgendaEntry' }
    & AgendaEntryDataFragment
  )> }
  & AgendaWeekBasicDataFragment
);

export type AgendaListBasicDataFragment = (
  { __typename?: 'AgendaList' }
  & Pick<AgendaList, 'id' | 'from' | 'to' | 'openFor' | 'status' | 'name'>
);

export type AgendaListDataFragment = (
  { __typename?: 'AgendaList' }
  & { weeks: Array<(
    { __typename?: 'AgendaWeek' }
    & AgendaWeekDataFragment
  )> }
  & AgendaListBasicDataFragment
);

export type AppointmentBasicDataFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'patientName' | 'patientTaxId' | 'patientProfessionalId' | 'from' | 'to' | 'day' | 'clinicId' | 'createdAt' | 'notes'>
  & { provision?: Maybe<(
    { __typename?: 'ProfessionalProvision' }
    & Pick<ProfessionalProvision, 'id'>
    & { provision: (
      { __typename?: 'Provision' }
      & Pick<Provision, 'id' | 'name'>
    ) }
  )> }
);

export type BreakBasicDataFragment = (
  { __typename?: 'Break' }
  & Pick<Break, 'id' | 'day' | 'from' | 'to' | 'createdAt' | 'clinicId' | 'notes'>
);

export type AppointmentDataFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'notes'>
  & { professional: (
    { __typename?: 'Assistant' }
    & ProfessionalData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalData_OrganizationSa_Fragment
  ), provision?: Maybe<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinic: (
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & ClinicDataFragment
    ) }
  ), creator: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
  & AppointmentBasicDataFragment
);

export type BreakDataFragment = (
  { __typename?: 'Break' }
  & Pick<Break, 'notes'>
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
  ), creator: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
  & BreakBasicDataFragment
);

type AgendaEventBasicData_Appointment_Fragment = (
  { __typename?: 'Appointment' }
  & AppointmentBasicDataFragment
);

type AgendaEventBasicData_Break_Fragment = (
  { __typename?: 'Break' }
  & BreakBasicDataFragment
);

export type AgendaEventBasicDataFragment = AgendaEventBasicData_Appointment_Fragment | AgendaEventBasicData_Break_Fragment;

type AgendaEventData_Appointment_Fragment = (
  { __typename?: 'Appointment' }
  & AppointmentDataFragment
);

type AgendaEventData_Break_Fragment = (
  { __typename?: 'Break' }
  & BreakDataFragment
);

export type AgendaEventDataFragment = AgendaEventData_Appointment_Fragment | AgendaEventData_Break_Fragment;

export type AttachmentDataFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'id' | 'url' | 'mimeType' | 'name'>
);

export type ClinicBasicDataFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone'>
  & { address: (
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  ) }
);

export type ClinicDataFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone'>
  & { rooms: Array<(
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  )>, address: (
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  ) }
);

export type ClinicRoomBasicDataFragment = (
  { __typename?: 'ClinicRoom' }
  & Pick<ClinicRoom, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone' | 'meta' | 'clinicId'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicBasicDataFragment
  ) }
);

export type ClinicRoomDataFragment = (
  { __typename?: 'ClinicRoom' }
  & { provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )> }
  & ClinicRoomBasicDataFragment
);

export type ClinicRoomFromDayDataFragment = (
  { __typename?: 'ClinicRoomFromDay' }
  & Pick<ClinicRoomFromDay, 'fromDay'>
  & { room: (
    { __typename?: 'ClinicRoom' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & { professional: (
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'name' | 'surname'>
        & { privacyPolicy?: Maybe<(
          { __typename?: 'Attachment' }
          & AttachmentDataFragment
        )> }
      ) | (
        { __typename?: 'Medic' }
        & Pick<Medic, 'name' | 'surname'>
        & { privacyPolicy?: Maybe<(
          { __typename?: 'Attachment' }
          & AttachmentDataFragment
        )> }
      ) | (
        { __typename?: 'Operator' }
        & Pick<Operator, 'name' | 'surname'>
        & { privacyPolicy?: Maybe<(
          { __typename?: 'Attachment' }
          & AttachmentDataFragment
        )> }
      ) | (
        { __typename?: 'OrganizationSa' }
        & Pick<OrganizationSa, 'name' | 'surname'>
        & { privacyPolicy?: Maybe<(
          { __typename?: 'Attachment' }
          & AttachmentDataFragment
        )> }
      ) }
      & ClinicDataFragment
    ) }
    & ClinicRoomDataFragment
  ) }
);

export type PaginationInfoDataFragment = (
  { __typename?: 'PaginationInfo' }
  & Pick<PaginationInfo, 'hasMore' | 'total'>
);

export type NotificationDataFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'userId' | 'readAt' | 'createdAt' | 'data' | 'name' | 'destinationProfile'>
);

export type PatientProfessionalDataFragment = (
  { __typename?: 'PatientProfessional' }
  & Pick<PatientProfessional, 'id' | 'status'>
  & { professional?: Maybe<(
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  )>, upcomingAppointments: Array<(
    { __typename?: 'Appointment' }
    & AppointmentDataFragment
  )> }
);

export type PatientBasicDataFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'birthDay' | 'createdAt' | 'createdBy' | 'name' | 'personalMedicId' | 'surname' | 'taxId' | 'updatedAt'>
);

export type PatientDataFragment = (
  { __typename?: 'Patient' }
  & { address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )>, patientProfessionals: Array<(
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  )>, users: Array<(
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  )>, personalMedic?: Maybe<(
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  )> }
  & PatientBasicDataFragment
);

export type PatientUserDataFragment = (
  { __typename?: 'PatientUser' }
  & Pick<PatientUser, 'id' | 'userId' | 'type' | 'patientId'>
  & { user: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
);

type ProfessionalData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & Pick<Assistant, 'id' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
);

type ProfessionalData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & MedicDataFragment
);

type ProfessionalData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OperatorDataFragment
);

type ProfessionalData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OrganizationSaDataFragment
);

export type ProfessionalDataFragment = ProfessionalData_Assistant_Fragment | ProfessionalData_Medic_Fragment | ProfessionalData_Operator_Fragment | ProfessionalData_OrganizationSa_Fragment;

type ProfessionalBasicData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & Pick<Assistant, 'id' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
);

type ProfessionalBasicData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & MedicBasicDataFragment
);

type ProfessionalBasicData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OperatorBasicDataFragment
);

type ProfessionalBasicData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OrganizationSaBasicDataFragment
);

export type ProfessionalBasicDataFragment = ProfessionalBasicData_Assistant_Fragment | ProfessionalBasicData_Medic_Fragment | ProfessionalBasicData_Operator_Fragment | ProfessionalBasicData_OrganizationSa_Fragment;

export type EmbedContactDataFragment = (
  { __typename?: 'EmbedContact' }
  & Pick<EmbedContact, 'email' | 'fax' | 'phone'>
);

export type MedicDataFragment = (
  { __typename?: 'Medic' }
  & { provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )> }
  & MedicBasicDataFragment
);

export type MedicBasicDataFragment = (
  { __typename?: 'Medic' }
  & Pick<Medic, 'id' | 'userId' | 'role' | 'name' | 'surname' | 'registerNumber' | 'type' | 'freeLancer' | 'chatEnabled' | 'requestsEnabled'>
  & { contacts?: Maybe<(
    { __typename?: 'EmbedContact' }
    & EmbedContactDataFragment
  )>, specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, registerDistrict?: Maybe<(
    { __typename?: 'City' }
    & CityDataFragment
  )>, address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )> }
);

export type OperatorDataFragment = (
  { __typename?: 'Operator' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )> }
  & OperatorBasicDataFragment
);

export type OperatorBasicDataFragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'role' | 'profession' | 'userId' | 'name' | 'surname' | 'chatEnabled' | 'requestsEnabled'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, contacts?: Maybe<(
    { __typename?: 'EmbedContact' }
    & EmbedContactDataFragment
  )>, address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )> }
);

export type OrganizationDataFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
  & { serviceAccount: (
    { __typename?: 'OrganizationSa' }
    & OrganizationSaBasicDataFragment
  ) }
);

export type OrganizationProfessionalsDataFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id'>
  & { professionals: Array<(
    { __typename?: 'OrganizationProfessional' }
    & OrganizationProfessionalPersonalDataFragment
  )> }
);

export type OrganizationSaDataFragment = (
  { __typename?: 'OrganizationSa' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )>, managedOrganization: (
    { __typename?: 'Organization' }
    & OrganizationProfessionalsDataFragment
  ) }
  & OrganizationSaBasicDataFragment
);

export type OrganizationSaBasicDataFragment = (
  { __typename?: 'OrganizationSa' }
  & Pick<OrganizationSa, 'id' | 'role' | 'userId' | 'name' | 'surname'>
);

export type OrganizationProfessionalDataFragment = (
  { __typename?: 'OrganizationProfessional' }
  & Pick<OrganizationProfessional, 'id' | 'role'>
  & { organization: (
    { __typename?: 'Organization' }
    & OrganizationDataFragment
  ) }
);

export type OrganizationProfessionalPersonalDataFragment = (
  { __typename?: 'OrganizationProfessional' }
  & Pick<OrganizationProfessional, 'id' | 'role'>
  & { professional: (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ) }
);

export type ProfessionalProvisionDataFragment = (
  { __typename?: 'ProfessionalProvision' }
  & Pick<ProfessionalProvision, 'id' | 'duration' | 'price' | 'mandatory' | 'createdAt' | 'updatedAt' | 'professionalId'>
  & { provision: (
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  ) }
);

export type ProvisionDataFragment = (
  { __typename?: 'Provision' }
  & Pick<Provision, 'id' | 'name' | 'defaultPrice' | 'defaultDuration' | 'code'>
);

export type ProvisionPaginatedDataFragment = (
  { __typename?: 'ProvisionPaginatedResponse' }
  & { items: Array<(
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  )>, paginationInfo: (
    { __typename?: 'PaginationInfo' }
    & Pick<PaginationInfo, 'hasMore' | 'total'>
  ) }
);

export type TimeSlotDataFragment = (
  { __typename?: 'TimeSlot' }
  & Pick<TimeSlot, 'day' | 'slots'>
);

export type CupProviderDataFragment = (
  { __typename?: 'CupProvider' }
  & Pick<CupProvider, 'id' | 'date' | 'duration' | 'price' | 'address' | 'addressName' | 'name' | 'url' | 'trackUrl'>
);

export type ReportBasicDataFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'title' | 'description' | 'createdBy' | 'reportDate' | 'createdAt' | 'medicName' | 'professionalId'>
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>> }
);

export type ReportDataFragment = (
  { __typename?: 'Report' }
  & { professional?: Maybe<(
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  )>, sharedWith: Array<(
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  )> }
  & ReportBasicDataFragment
);

type RequestAuthorData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & Pick<Medic, 'id' | 'role' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

type RequestAuthorData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'name' | 'surname' | 'role'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

type RequestAuthorData_Patient_Fragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name' | 'surname' | 'taxId'>
);

type RequestAuthorData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & Pick<OrganizationSa, 'id' | 'name' | 'surname' | 'role'>
);

export type RequestAuthorDataFragment = RequestAuthorData_Medic_Fragment | RequestAuthorData_Operator_Fragment | RequestAuthorData_Patient_Fragment | RequestAuthorData_OrganizationSa_Fragment;

export type RequestMessageDataFragment = (
  { __typename?: 'RequestMessage' }
  & Pick<RequestMessage, 'id' | 'message' | 'readAt' | 'createdAt'>
  & { creator: (
    { __typename?: 'User' }
    & UserDataFragment
  ), author?: Maybe<(
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  )>, attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>> }
);

export type RequestDataFragment = (
  { __typename?: 'Request' }
  & Pick<Request, 'id' | 'title' | 'type' | 'result' | 'private' | 'createdAt'>
  & { messages: Array<(
    { __typename?: 'RequestMessage' }
    & RequestMessageDataFragment
  )>, recipient: (
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  ), sender: (
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  ) }
);

export type SpecializationDataFragment = (
  { __typename?: 'Specialization' }
  & Pick<Specialization, 'id' | 'name'>
);

export type UserTraitsDataFragment = (
  { __typename?: 'UserTraits' }
  & Pick<UserTraits, 'email' | 'avatar' | 'taxId' | 'name' | 'surname' | 'birthDay'>
);

export type UserEmailAddressDataFragment = (
  { __typename?: 'UserEmailAddress' }
  & Pick<UserEmailAddress, 'id' | 'value' | 'verified' | 'via' | 'status'>
);

export type UserDataFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'role'>
  & { traits: (
    { __typename?: 'UserTraits' }
    & UserTraitsDataFragment
  ) }
);

export type BookProvisionMutationVariables = Exact<{
  appointment: CreateAppointmentInput;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
}>;


export type BookProvisionMutation = (
  { __typename?: 'Mutation' }
  & { createAppointmentAsPatient: (
    { __typename?: 'Appointment' }
    & AppointmentDataFragment
  ) }
);

export type DeleteAppointmentMutationVariables = Exact<{
  id: Scalars['String'];
  patientId: Scalars['String'];
}>;


export type DeleteAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAppointmentAsPatient'>
);

export type ChatAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type ChatAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getChatAccessToken'>
);

export type StartChatMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type StartChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createChat'>
);

export type CreatePatientProfessionalMutationVariables = Exact<{
  professionalId: Scalars['String'];
  patientId: Scalars['String'];
}>;


export type CreatePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { createPatientProfessionalAsPatient: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationAsRead: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export type ReadManyNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReadManyNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { markManyNotificationsAsRead: Array<(
    { __typename?: 'Notification' }
    & NotificationDataFragment
  )> }
);

export type AssignPersonalMedicMutationVariables = Exact<{
  medicId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
}>;


export type AssignPersonalMedicMutation = (
  { __typename?: 'Mutation' }
  & { assignPersonalMedic?: Maybe<(
    { __typename?: 'Medic' }
    & ProfessionalData_Medic_Fragment
  )> }
);

export type UpdatePatientProfessionalStatusMutationVariables = Exact<{
  id: Scalars['String'];
  patientId: Scalars['String'];
  status: UpdatablePatientProfessionalStatus;
}>;


export type UpdatePatientProfessionalStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientProfessionalStatus: (
    { __typename?: 'PatientProfessional' }
    & Pick<PatientProfessional, 'id' | 'status'>
  ) }
);

export type ArchivePatientProfessionalMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ArchivePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { archivePatientProfessional: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type CreateUserPatientMutationVariables = Exact<{
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<CreateUserPatientInput>;
  termsAndConditions: Scalars['Boolean'];
  type?: Maybe<PatientType>;
}>;


export type CreateUserPatientMutation = (
  { __typename?: 'Mutation' }
  & { createUserPatient: (
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  ) }
);

export type CreateReportMutationVariables = Exact<{
  patientProfessionalId?: Maybe<Scalars['String']>;
  report: CreateReportInput;
  patientId: Scalars['String'];
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReportAsPatient: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type ShareReportMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
}>;


export type ShareReportMutation = (
  { __typename?: 'Mutation' }
  & { shareReport: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type RevokeReportShareMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
}>;


export type RevokeReportShareMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeReportShare'>
);

export type CreateRequestMutationVariables = Exact<{
  request: CreateRequestInput;
  patientId: Scalars['String'];
}>;


export type CreateRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRequestAsPatient: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type DeleteRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRequest'>
);

export type AppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AppointmentQuery = (
  { __typename?: 'Query' }
  & { appointment: (
    { __typename?: 'Appointment' }
    & AppointmentDataFragment
  ) }
);

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = (
  { __typename?: 'Query' }
  & { whoami: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
);

export type NotificationsQueryVariables = Exact<{
  filters?: Maybe<NotificationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type NotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationQuery = (
  { __typename?: 'Query' }
  & { notification: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export type PatientsQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientsQuery = (
  { __typename?: 'Query' }
  & { patientProfiles?: Maybe<Array<(
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  )>> }
);

export type PatientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientDataFragment
  ) }
);

export type SearchPatientsQueryVariables = Exact<{
  filter?: Maybe<PatientFilterInput>;
}>;


export type SearchPatientsQuery = (
  { __typename?: 'Query' }
  & { patients: (
    { __typename?: 'PatientPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Patient' }
      & PatientDataFragment
    )> }
  ) }
);

export type PatientToAssociateQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientToAssociateQuery = (
  { __typename?: 'Query' }
  & { patientToAssociate?: Maybe<(
    { __typename?: 'Patient' }
    & PatientDataFragment
  )> }
);

export type ProfessionalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProfessionalQuery = (
  { __typename?: 'Query' }
  & { professional: (
    { __typename?: 'Assistant' }
    & ProfessionalData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalData_OrganizationSa_Fragment
  ) }
);

export type ProfessionalsQueryVariables = Exact<{
  name: Scalars['String'];
  roleFilter?: Maybe<ProfessionalRoleFieldFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  exclude?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ProfessionalsQuery = (
  { __typename?: 'Query' }
  & { professionals: (
    { __typename?: 'ProfessionalPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Assistant' }
      & ProfessionalBasicData_Assistant_Fragment
    ) | (
      { __typename?: 'Medic' }
      & ProfessionalBasicData_Medic_Fragment
    ) | (
      { __typename?: 'Operator' }
      & ProfessionalBasicData_Operator_Fragment
    ) | (
      { __typename?: 'OrganizationSa' }
      & ProfessionalBasicData_OrganizationSa_Fragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type DefaultProvisionsQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultProvisionsQuery = (
  { __typename?: 'Query' }
  & { defaultProvisions: Array<(
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  )> }
);

export type ProvisionsQueryVariables = Exact<{
  filter?: Maybe<ProvisionFilterInput>;
}>;


export type ProvisionsQuery = (
  { __typename?: 'Query' }
  & { provisions: (
    { __typename?: 'ProvisionPaginatedResponse' }
    & ProvisionPaginatedDataFragment
  ) }
);

export type ClinicRoomByProvisionQueryVariables = Exact<{
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
}>;


export type ClinicRoomByProvisionQuery = (
  { __typename?: 'Query' }
  & { clinicRoomByProvision: Array<(
    { __typename?: 'ClinicRoomFromDay' }
    & ClinicRoomFromDayDataFragment
  )> }
);

export type CupProvidersQueryVariables = Exact<{
  coordinates: LatLngInput;
  provisionId: Scalars['String'];
}>;


export type CupProvidersQuery = (
  { __typename?: 'Query' }
  & { cupProviders: Array<(
    { __typename?: 'CupProvider' }
    & CupProviderDataFragment
  )> }
);

export type TimeSlotsQueryVariables = Exact<{
  clinicIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  provisionId: Scalars['String'];
}>;


export type TimeSlotsQuery = (
  { __typename?: 'Query' }
  & { timeslots: Array<(
    { __typename?: 'TimeSlot' }
    & TimeSlotDataFragment
  )> }
);

export type ReportsQueryVariables = Exact<{
  patientId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { patientReports: (
    { __typename?: 'ReportsPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Report' }
      & ReportBasicDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type ReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type RequestsQueryVariables = Exact<{
  patientId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type RequestsQuery = (
  { __typename?: 'Query' }
  & { sentRequestsAsPatient: (
    { __typename?: 'RequestPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Request' }
      & RequestDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type RequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RequestQuery = (
  { __typename?: 'Query' }
  & { request: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type OnNewNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnNewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onNewNotification: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export const CityDataFragmentDoc = gql`
    fragment CityData on City {
  id
  registryCode
  name
  region {
    name
    code
  }
  district {
    name
    code
  }
}
    `;
export const EmbedAddressDataFragmentDoc = gql`
    fragment EmbedAddressData on EmbedAddress {
  municipalityName
  districtName
  address
  location
  cap
  municipalityId
  municipality {
    ...CityData
  }
}
    ${CityDataFragmentDoc}`;
export const ClinicBasicDataFragmentDoc = gql`
    fragment ClinicBasicData on Clinic {
  id
  createdAt
  updatedAt
  name
  phone
  address {
    ...EmbedAddressData
  }
}
    ${EmbedAddressDataFragmentDoc}`;
export const ClinicRoomBasicDataFragmentDoc = gql`
    fragment ClinicRoomBasicData on ClinicRoom {
  id
  createdAt
  updatedAt
  name
  phone
  meta
  clinicId
  clinic {
    ...ClinicBasicData
  }
}
    ${ClinicBasicDataFragmentDoc}`;
export const ProvisionDataFragmentDoc = gql`
    fragment ProvisionData on Provision {
  id
  name
  defaultPrice
  defaultDuration
  code
}
    `;
export const ProfessionalProvisionDataFragmentDoc = gql`
    fragment ProfessionalProvisionData on ProfessionalProvision {
  id
  duration
  price
  mandatory
  createdAt
  updatedAt
  professionalId
  provision {
    ...ProvisionData
  }
}
    ${ProvisionDataFragmentDoc}`;
export const ClinicRoomDataFragmentDoc = gql`
    fragment ClinicRoomData on ClinicRoom {
  ...ClinicRoomBasicData
  provisions {
    ...ProfessionalProvisionData
  }
}
    ${ClinicRoomBasicDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}`;
export const AgendaEntryInDayDataFragmentDoc = gql`
    fragment AgendaEntryInDayData on AgendaEntryInDay {
  day
  id
  disabled
  weekday
  override
  informers
  assistantIds
  visibility
  agendaWeekId
  from
  to
  clinicId
  clinic {
    ...ClinicRoomData
  }
}
    ${ClinicRoomDataFragmentDoc}`;
export const AgendaListBasicDataFragmentDoc = gql`
    fragment AgendaListBasicData on AgendaList {
  id
  from
  to
  openFor
  status
  name
}
    `;
export const AgendaWeekBasicDataFragmentDoc = gql`
    fragment AgendaWeekBasicData on AgendaWeek {
  id
  order
}
    `;
export const AgendaEntryBasicDataFragmentDoc = gql`
    fragment AgendaEntryBasicData on AgendaEntry {
  id
  disabled
  weekday
  override
  informers
  assistantIds
  visibility
  agendaWeekId
  from
  to
  clinicId
}
    `;
export const AgendaEntryDataFragmentDoc = gql`
    fragment AgendaEntryData on AgendaEntry {
  ...AgendaEntryBasicData
  clinic {
    ...ClinicRoomData
  }
}
    ${AgendaEntryBasicDataFragmentDoc}
${ClinicRoomDataFragmentDoc}`;
export const AgendaWeekDataFragmentDoc = gql`
    fragment AgendaWeekData on AgendaWeek {
  ...AgendaWeekBasicData
  entries {
    ...AgendaEntryData
  }
}
    ${AgendaWeekBasicDataFragmentDoc}
${AgendaEntryDataFragmentDoc}`;
export const AgendaListDataFragmentDoc = gql`
    fragment AgendaListData on AgendaList {
  ...AgendaListBasicData
  weeks {
    ...AgendaWeekData
  }
}
    ${AgendaListBasicDataFragmentDoc}
${AgendaWeekDataFragmentDoc}`;
export const AppointmentBasicDataFragmentDoc = gql`
    fragment AppointmentBasicData on Appointment {
  id
  patientName
  patientTaxId
  patientProfessionalId
  from
  to
  day
  clinicId
  createdAt
  notes
  provision {
    id
    provision {
      id
      name
    }
  }
}
    `;
export const BreakBasicDataFragmentDoc = gql`
    fragment BreakBasicData on Break {
  id
  day
  from
  to
  createdAt
  clinicId
  notes
}
    `;
export const AgendaEventBasicDataFragmentDoc = gql`
    fragment AgendaEventBasicData on AgendaEvent {
  ... on Appointment {
    ...AppointmentBasicData
  }
  ... on Break {
    ...BreakBasicData
  }
}
    ${AppointmentBasicDataFragmentDoc}
${BreakBasicDataFragmentDoc}`;
export const UserTraitsDataFragmentDoc = gql`
    fragment UserTraitsData on UserTraits {
  email
  avatar
  taxId
  name
  surname
  birthDay
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  role
  traits {
    ...UserTraitsData
  }
}
    ${UserTraitsDataFragmentDoc}`;
export const EmbedContactDataFragmentDoc = gql`
    fragment EmbedContactData on EmbedContact {
  email
  fax
  phone
}
    `;
export const SpecializationDataFragmentDoc = gql`
    fragment SpecializationData on Specialization {
  id
  name
}
    `;
export const MedicBasicDataFragmentDoc = gql`
    fragment MedicBasicData on Medic {
  id
  userId
  role
  name
  surname
  registerNumber
  type
  contacts {
    ...EmbedContactData
  }
  freeLancer
  chatEnabled
  requestsEnabled
  specializations {
    ...SpecializationData
  }
  user {
    ...UserData
  }
  registerDistrict {
    ...CityData
  }
  address {
    ...EmbedAddressData
  }
}
    ${EmbedContactDataFragmentDoc}
${SpecializationDataFragmentDoc}
${UserDataFragmentDoc}
${CityDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const ClinicDataFragmentDoc = gql`
    fragment ClinicData on Clinic {
  id
  createdAt
  updatedAt
  name
  phone
  rooms {
    ...ClinicRoomData
  }
  address {
    ...EmbedAddressData
  }
}
    ${ClinicRoomDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const MedicDataFragmentDoc = gql`
    fragment MedicData on Medic {
  ...MedicBasicData
  provisions {
    ...ProfessionalProvisionData
  }
  clinics {
    ...ClinicData
  }
}
    ${MedicBasicDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}`;
export const OperatorBasicDataFragmentDoc = gql`
    fragment OperatorBasicData on Operator {
  id
  role
  profession
  userId
  name
  surname
  chatEnabled
  requestsEnabled
  user {
    ...UserData
  }
  contacts {
    ...EmbedContactData
  }
  address {
    ...EmbedAddressData
  }
}
    ${UserDataFragmentDoc}
${EmbedContactDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const OperatorDataFragmentDoc = gql`
    fragment OperatorData on Operator {
  ...OperatorBasicData
  specializations {
    ...SpecializationData
  }
  provisions {
    ...ProfessionalProvisionData
  }
  clinics {
    ...ClinicData
  }
}
    ${OperatorBasicDataFragmentDoc}
${SpecializationDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}`;
export const OrganizationSaBasicDataFragmentDoc = gql`
    fragment OrganizationSaBasicData on OrganizationSa {
  id
  role
  userId
  name
  surname
}
    `;
export const AttachmentDataFragmentDoc = gql`
    fragment AttachmentData on Attachment {
  id
  url
  mimeType
  name
}
    `;
export const ProfessionalBasicDataFragmentDoc = gql`
    fragment ProfessionalBasicData on Professional {
  ... on Medic {
    ...MedicBasicData
  }
  ... on Operator {
    ...OperatorBasicData
  }
  ... on OrganizationSa {
    ...OrganizationSaBasicData
  }
  user {
    ...UserData
  }
  ... on Assistant {
    id
    name
    surname
  }
  privacyPolicy {
    ...AttachmentData
  }
}
    ${MedicBasicDataFragmentDoc}
${OperatorBasicDataFragmentDoc}
${OrganizationSaBasicDataFragmentDoc}
${UserDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const OrganizationProfessionalPersonalDataFragmentDoc = gql`
    fragment OrganizationProfessionalPersonalData on OrganizationProfessional {
  id
  role
  professional {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const OrganizationProfessionalsDataFragmentDoc = gql`
    fragment OrganizationProfessionalsData on Organization {
  id
  professionals {
    ...OrganizationProfessionalPersonalData
  }
}
    ${OrganizationProfessionalPersonalDataFragmentDoc}`;
export const OrganizationSaDataFragmentDoc = gql`
    fragment OrganizationSaData on OrganizationSa {
  ...OrganizationSaBasicData
  clinics {
    ...ClinicData
  }
  managedOrganization {
    ...OrganizationProfessionalsData
  }
}
    ${OrganizationSaBasicDataFragmentDoc}
${ClinicDataFragmentDoc}
${OrganizationProfessionalsDataFragmentDoc}`;
export const ProfessionalDataFragmentDoc = gql`
    fragment ProfessionalData on Professional {
  user {
    ...UserData
  }
  ... on Medic {
    ...MedicData
  }
  ... on Operator {
    ...OperatorData
  }
  ... on OrganizationSa {
    ...OrganizationSaData
  }
  ... on Assistant {
    id
    name
    surname
  }
  privacyPolicy {
    ...AttachmentData
  }
}
    ${UserDataFragmentDoc}
${MedicDataFragmentDoc}
${OperatorDataFragmentDoc}
${OrganizationSaDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const AppointmentDataFragmentDoc = gql`
    fragment AppointmentData on Appointment {
  ...AppointmentBasicData
  professional {
    ...ProfessionalData
  }
  provision {
    ...ProfessionalProvisionData
  }
  clinic {
    id
    name
    phone
    clinic {
      ...ClinicData
    }
  }
  notes
  creator {
    ...UserData
  }
}
    ${AppointmentBasicDataFragmentDoc}
${ProfessionalDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const BreakDataFragmentDoc = gql`
    fragment BreakData on Break {
  ...BreakBasicData
  clinic {
    id
    name
    phone
  }
  notes
  creator {
    ...UserData
  }
}
    ${BreakBasicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const AgendaEventDataFragmentDoc = gql`
    fragment AgendaEventData on AgendaEvent {
  ... on Appointment {
    ...AppointmentData
  }
  ... on Break {
    ...BreakData
  }
}
    ${AppointmentDataFragmentDoc}
${BreakDataFragmentDoc}`;
export const ClinicRoomFromDayDataFragmentDoc = gql`
    fragment ClinicRoomFromDayData on ClinicRoomFromDay {
  room {
    ...ClinicRoomData
    clinic {
      ...ClinicData
      professional {
        name
        surname
        privacyPolicy {
          ...AttachmentData
        }
      }
    }
  }
  fromDay
}
    ${ClinicRoomDataFragmentDoc}
${ClinicDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const PaginationInfoDataFragmentDoc = gql`
    fragment PaginationInfoData on PaginationInfo {
  hasMore
  total
}
    `;
export const NotificationDataFragmentDoc = gql`
    fragment NotificationData on Notification {
  id
  userId
  readAt
  createdAt
  data
  name
  destinationProfile
}
    `;
export const PatientBasicDataFragmentDoc = gql`
    fragment PatientBasicData on Patient {
  id
  birthDay
  createdAt
  createdBy
  name
  personalMedicId
  surname
  taxId
  updatedAt
}
    `;
export const PatientProfessionalDataFragmentDoc = gql`
    fragment PatientProfessionalData on PatientProfessional {
  id
  status
  professional {
    ...ProfessionalBasicData
  }
  upcomingAppointments {
    ...AppointmentData
  }
}
    ${ProfessionalBasicDataFragmentDoc}
${AppointmentDataFragmentDoc}`;
export const PatientUserDataFragmentDoc = gql`
    fragment PatientUserData on PatientUser {
  id
  userId
  type
  patientId
  user {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export const PatientDataFragmentDoc = gql`
    fragment PatientData on Patient {
  ...PatientBasicData
  address {
    ...EmbedAddressData
  }
  patientProfessionals {
    ...PatientProfessionalData
  }
  users {
    ...PatientUserData
  }
  personalMedic {
    ...ProfessionalBasicData
  }
}
    ${PatientBasicDataFragmentDoc}
${EmbedAddressDataFragmentDoc}
${PatientProfessionalDataFragmentDoc}
${PatientUserDataFragmentDoc}
${ProfessionalBasicDataFragmentDoc}`;
export const OrganizationDataFragmentDoc = gql`
    fragment OrganizationData on Organization {
  id
  name
  serviceAccount {
    ...OrganizationSaBasicData
  }
}
    ${OrganizationSaBasicDataFragmentDoc}`;
export const OrganizationProfessionalDataFragmentDoc = gql`
    fragment OrganizationProfessionalData on OrganizationProfessional {
  id
  role
  organization {
    ...OrganizationData
  }
}
    ${OrganizationDataFragmentDoc}`;
export const ProvisionPaginatedDataFragmentDoc = gql`
    fragment ProvisionPaginatedData on ProvisionPaginatedResponse {
  items {
    ...ProvisionData
  }
  paginationInfo {
    hasMore
    total
  }
}
    ${ProvisionDataFragmentDoc}`;
export const TimeSlotDataFragmentDoc = gql`
    fragment TimeSlotData on TimeSlot {
  day
  slots
}
    `;
export const CupProviderDataFragmentDoc = gql`
    fragment CupProviderData on CupProvider {
  id
  date
  duration
  price
  address
  addressName
  name
  url
  trackUrl
}
    `;
export const ReportBasicDataFragmentDoc = gql`
    fragment ReportBasicData on Report {
  id
  title
  description
  createdBy
  reportDate
  createdAt
  medicName
  professionalId
  attachments {
    ...AttachmentData
  }
}
    ${AttachmentDataFragmentDoc}`;
export const ReportDataFragmentDoc = gql`
    fragment ReportData on Report {
  ...ReportBasicData
  professional {
    ...ProfessionalBasicData
  }
  sharedWith {
    ...PatientProfessionalData
  }
}
    ${ReportBasicDataFragmentDoc}
${ProfessionalBasicDataFragmentDoc}
${PatientProfessionalDataFragmentDoc}`;
export const RequestAuthorDataFragmentDoc = gql`
    fragment RequestAuthorData on RequestAuthor {
  ... on Patient {
    id
    name
    surname
    taxId
  }
  ... on Medic {
    id
    role
    name
    surname
    user {
      ...UserData
    }
  }
  ... on Operator {
    id
    name
    surname
    role
    user {
      ...UserData
    }
  }
  ... on OrganizationSa {
    id
    name
    surname
    role
  }
}
    ${UserDataFragmentDoc}`;
export const RequestMessageDataFragmentDoc = gql`
    fragment RequestMessageData on RequestMessage {
  id
  message
  readAt
  creator {
    ...UserData
  }
  author {
    ...RequestAuthorData
  }
  attachments {
    ...AttachmentData
  }
  createdAt
}
    ${UserDataFragmentDoc}
${RequestAuthorDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const RequestDataFragmentDoc = gql`
    fragment RequestData on Request {
  id
  title
  type
  result
  private
  createdAt
  messages {
    ...RequestMessageData
  }
  recipient {
    ...RequestAuthorData
  }
  sender {
    ...RequestAuthorData
  }
}
    ${RequestMessageDataFragmentDoc}
${RequestAuthorDataFragmentDoc}`;
export const UserEmailAddressDataFragmentDoc = gql`
    fragment UserEmailAddressData on UserEmailAddress {
  id
  value
  verified
  via
  status
}
    `;
export const BookProvisionDocument = gql`
    mutation BookProvision($appointment: CreateAppointmentInput!, $patientId: String!, $medicId: String!) {
  createAppointmentAsPatient(
    appointment: $appointment
    patientId: $patientId
    medicId: $medicId
  ) {
    ...AppointmentData
  }
}
    ${AppointmentDataFragmentDoc}`;
export type BookProvisionMutationResult = ApolloReactCommon.MutationResult<BookProvisionMutation>;
export type BookProvisionMutationOptions = ApolloReactCommon.BaseMutationOptions<BookProvisionMutation, BookProvisionMutationVariables>;
export const DeleteAppointmentDocument = gql`
    mutation DeleteAppointment($id: String!, $patientId: String!) {
  deleteAppointmentAsPatient(id: $id, patientId: $patientId)
}
    `;
export type DeleteAppointmentMutationResult = ApolloReactCommon.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>;
export const ChatAccessTokenDocument = gql`
    mutation ChatAccessToken {
  getChatAccessToken
}
    `;
export type ChatAccessTokenMutationResult = ApolloReactCommon.MutationResult<ChatAccessTokenMutation>;
export type ChatAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatAccessTokenMutation, ChatAccessTokenMutationVariables>;
export const StartChatDocument = gql`
    mutation StartChat($userId: ID!) {
  createChat(userId: $userId)
}
    `;
export type StartChatMutationResult = ApolloReactCommon.MutationResult<StartChatMutation>;
export type StartChatMutationOptions = ApolloReactCommon.BaseMutationOptions<StartChatMutation, StartChatMutationVariables>;
export const CreatePatientProfessionalDocument = gql`
    mutation CreatePatientProfessional($professionalId: String!, $patientId: String!) {
  createPatientProfessionalAsPatient(
    patientId: $patientId
    professionalId: $professionalId
  ) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type CreatePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<CreatePatientProfessionalMutation>;
export type CreatePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientProfessionalMutation, CreatePatientProfessionalMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: String!) {
  markNotificationAsRead(id: $id) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type ReadNotificationMutationResult = ApolloReactCommon.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ReadManyNotificationsDocument = gql`
    mutation ReadManyNotifications($ids: [String!]!) {
  markManyNotificationsAsRead(ids: $ids) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type ReadManyNotificationsMutationResult = ApolloReactCommon.MutationResult<ReadManyNotificationsMutation>;
export type ReadManyNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadManyNotificationsMutation, ReadManyNotificationsMutationVariables>;
export const AssignPersonalMedicDocument = gql`
    mutation AssignPersonalMedic($medicId: String, $patientId: String!) {
  assignPersonalMedic(medicId: $medicId, patientId: $patientId) {
    ...ProfessionalData
  }
}
    ${ProfessionalDataFragmentDoc}`;
export type AssignPersonalMedicMutationResult = ApolloReactCommon.MutationResult<AssignPersonalMedicMutation>;
export type AssignPersonalMedicMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignPersonalMedicMutation, AssignPersonalMedicMutationVariables>;
export const UpdatePatientProfessionalStatusDocument = gql`
    mutation UpdatePatientProfessionalStatus($id: String!, $patientId: String!, $status: UpdatablePatientProfessionalStatus!) {
  updatePatientProfessionalStatus(id: $id, patientId: $patientId, status: $status) {
    id
    status
  }
}
    `;
export type UpdatePatientProfessionalStatusMutationResult = ApolloReactCommon.MutationResult<UpdatePatientProfessionalStatusMutation>;
export type UpdatePatientProfessionalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientProfessionalStatusMutation, UpdatePatientProfessionalStatusMutationVariables>;
export const ArchivePatientProfessionalDocument = gql`
    mutation ArchivePatientProfessional($id: String!) {
  archivePatientProfessional(id: $id) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type ArchivePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<ArchivePatientProfessionalMutation>;
export type ArchivePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchivePatientProfessionalMutation, ArchivePatientProfessionalMutationVariables>;
export const CreateUserPatientDocument = gql`
    mutation CreateUserPatient($patientId: String, $patient: CreateUserPatientInput, $termsAndConditions: Boolean!, $type: PatientType) {
  createUserPatient(
    patientId: $patientId
    patient: $patient
    termsAndConditions: $termsAndConditions
    type: $type
  ) {
    ...PatientUserData
  }
}
    ${PatientUserDataFragmentDoc}`;
export type CreateUserPatientMutationResult = ApolloReactCommon.MutationResult<CreateUserPatientMutation>;
export type CreateUserPatientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserPatientMutation, CreateUserPatientMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($patientProfessionalId: String, $report: CreateReportInput!, $patientId: String!) {
  createReportAsPatient(
    patientProfessionalId: $patientProfessionalId
    report: $report
    patientId: $patientId
  ) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const ShareReportDocument = gql`
    mutation ShareReport($patientProfessionalId: String!, $id: String!) {
  shareReport(patientProfessionalId: $patientProfessionalId, id: $id) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type ShareReportMutationResult = ApolloReactCommon.MutationResult<ShareReportMutation>;
export type ShareReportMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareReportMutation, ShareReportMutationVariables>;
export const RevokeReportShareDocument = gql`
    mutation RevokeReportShare($patientProfessionalId: String!, $id: String!) {
  revokeReportShare(patientProfessionalId: $patientProfessionalId, id: $id)
}
    `;
export type RevokeReportShareMutationResult = ApolloReactCommon.MutationResult<RevokeReportShareMutation>;
export type RevokeReportShareMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeReportShareMutation, RevokeReportShareMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($request: CreateRequestInput!, $patientId: String!) {
  createRequestAsPatient(request: $request, patientId: $patientId) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type CreateRequestMutationResult = ApolloReactCommon.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const DeleteRequestDocument = gql`
    mutation DeleteRequest($id: String!) {
  deleteRequest(id: $id)
}
    `;
export type DeleteRequestMutationResult = ApolloReactCommon.MutationResult<DeleteRequestMutation>;
export type DeleteRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const AppointmentDocument = gql`
    query Appointment($id: ID!) {
  appointment(id: $id) {
    ...AppointmentData
  }
}
    ${AppointmentDataFragmentDoc}`;
export type AppointmentQueryResult = ApolloReactCommon.QueryResult<AppointmentQuery, AppointmentQueryVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoami {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type WhoAmIQueryResult = ApolloReactCommon.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($filters: NotificationFilterInput, $skip: Int = 0, $limit: Int = 25) {
  notifications(
    skip: $skip
    limit: $limit
    filter: $filters
    order: {createdAt: DESC}
  ) {
    items {
      ...NotificationData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${NotificationDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const NotificationDocument = gql`
    query Notification($id: ID!) {
  notification(id: $id) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type NotificationQueryResult = ApolloReactCommon.QueryResult<NotificationQuery, NotificationQueryVariables>;
export const PatientsDocument = gql`
    query Patients {
  patientProfiles {
    ...PatientUserData
  }
}
    ${PatientUserDataFragmentDoc}`;
export type PatientsQueryResult = ApolloReactCommon.QueryResult<PatientsQuery, PatientsQueryVariables>;
export const PatientDocument = gql`
    query Patient($id: ID!) {
  patient(id: $id) {
    ...PatientData
  }
}
    ${PatientDataFragmentDoc}`;
export type PatientQueryResult = ApolloReactCommon.QueryResult<PatientQuery, PatientQueryVariables>;
export const SearchPatientsDocument = gql`
    query SearchPatients($filter: PatientFilterInput) {
  patients(filter: $filter) {
    items {
      ...PatientData
    }
  }
}
    ${PatientDataFragmentDoc}`;
export type SearchPatientsQueryResult = ApolloReactCommon.QueryResult<SearchPatientsQuery, SearchPatientsQueryVariables>;
export const PatientToAssociateDocument = gql`
    query PatientToAssociate {
  patientToAssociate {
    ...PatientData
  }
}
    ${PatientDataFragmentDoc}`;
export type PatientToAssociateQueryResult = ApolloReactCommon.QueryResult<PatientToAssociateQuery, PatientToAssociateQueryVariables>;
export const ProfessionalDocument = gql`
    query Professional($id: ID!) {
  professional(id: $id) {
    ...ProfessionalData
  }
}
    ${ProfessionalDataFragmentDoc}`;
export type ProfessionalQueryResult = ApolloReactCommon.QueryResult<ProfessionalQuery, ProfessionalQueryVariables>;
export const ProfessionalsDocument = gql`
    query Professionals($name: String!, $roleFilter: ProfessionalRoleFieldFilterInput, $limit: Int = 25, $skip: Int = 0, $exclude: [ID!] = []) {
  professionals(
    limit: $limit
    skip: $skip
    filter: {role: $roleFilter, id: {nin: $exclude}, _or: [{name: {like: $name}}, {surname: {like: $name}}]}
  ) {
    items {
      ...ProfessionalBasicData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${ProfessionalBasicDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ProfessionalsQueryResult = ApolloReactCommon.QueryResult<ProfessionalsQuery, ProfessionalsQueryVariables>;
export const DefaultProvisionsDocument = gql`
    query DefaultProvisions {
  defaultProvisions {
    ...ProvisionData
  }
}
    ${ProvisionDataFragmentDoc}`;
export type DefaultProvisionsQueryResult = ApolloReactCommon.QueryResult<DefaultProvisionsQuery, DefaultProvisionsQueryVariables>;
export const ProvisionsDocument = gql`
    query Provisions($filter: ProvisionFilterInput) {
  provisions(filter: $filter) {
    ...ProvisionPaginatedData
  }
}
    ${ProvisionPaginatedDataFragmentDoc}`;
export type ProvisionsQueryResult = ApolloReactCommon.QueryResult<ProvisionsQuery, ProvisionsQueryVariables>;
export const ClinicRoomByProvisionDocument = gql`
    query ClinicRoomByProvision($distance: Int, $coordinates: LatLngInput, $medicId: String, $from: Date!, $patientId: String!, $provisionId: String!) {
  clinicRoomByProvision(
    distance: $distance
    coordinates: $coordinates
    medicId: $medicId
    from: $from
    patientId: $patientId
    provisionId: $provisionId
  ) {
    ...ClinicRoomFromDayData
  }
}
    ${ClinicRoomFromDayDataFragmentDoc}`;
export type ClinicRoomByProvisionQueryResult = ApolloReactCommon.QueryResult<ClinicRoomByProvisionQuery, ClinicRoomByProvisionQueryVariables>;
export const CupProvidersDocument = gql`
    query CupProviders($coordinates: LatLngInput!, $provisionId: String!) {
  cupProviders(coordinates: $coordinates, provisionId: $provisionId) {
    ...CupProviderData
  }
}
    ${CupProviderDataFragmentDoc}`;
export type CupProvidersQueryResult = ApolloReactCommon.QueryResult<CupProvidersQuery, CupProvidersQueryVariables>;
export const TimeSlotsDocument = gql`
    query TimeSlots($clinicIds: [String!], $to: Date!, $from: Date!, $provisionId: String!) {
  timeslots(
    clinicIds: $clinicIds
    to: $to
    from: $from
    provisionId: $provisionId
  ) {
    ...TimeSlotData
  }
}
    ${TimeSlotDataFragmentDoc}`;
export type TimeSlotsQueryResult = ApolloReactCommon.QueryResult<TimeSlotsQuery, TimeSlotsQueryVariables>;
export const ReportsDocument = gql`
    query Reports($patientId: String!, $skip: Int = 0, $limit: Int = 10) {
  patientReports(patientId: $patientId, skip: $skip, limit: $limit) {
    items {
      ...ReportBasicData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${ReportBasicDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ReportsQueryResult = ApolloReactCommon.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ReportDocument = gql`
    query Report($id: ID!) {
  report(id: $id) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type ReportQueryResult = ApolloReactCommon.QueryResult<ReportQuery, ReportQueryVariables>;
export const RequestsDocument = gql`
    query Requests($patientId: String!, $skip: Int = 0, $limit: Int = 10) {
  sentRequestsAsPatient(
    patientId: $patientId
    skip: $skip
    limit: $limit
    order: {createdAt: DESC}
  ) {
    items {
      ...RequestData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${RequestDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type RequestsQueryResult = ApolloReactCommon.QueryResult<RequestsQuery, RequestsQueryVariables>;
export const RequestDocument = gql`
    query Request($id: ID!) {
  request(id: $id) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type RequestQueryResult = ApolloReactCommon.QueryResult<RequestQuery, RequestQueryVariables>;
export const OnNewNotificationDocument = gql`
    subscription OnNewNotification {
  onNewNotification {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type OnNewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnNewNotificationSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "AgendaEvent",
        "possibleTypes": [
          {
            "name": "Appointment"
          },
          {
            "name": "Break"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BaseEntity",
        "possibleTypes": [
          {
            "name": "AgendaEntry"
          },
          {
            "name": "AgendaList"
          },
          {
            "name": "AgendaWeek"
          },
          {
            "name": "Appointment"
          },
          {
            "name": "Assistant"
          },
          {
            "name": "Attachment"
          },
          {
            "name": "Break"
          },
          {
            "name": "City"
          },
          {
            "name": "Clinic"
          },
          {
            "name": "ClinicRoom"
          },
          {
            "name": "Invite"
          },
          {
            "name": "License"
          },
          {
            "name": "Medic"
          },
          {
            "name": "Network"
          },
          {
            "name": "Notification"
          },
          {
            "name": "Operator"
          },
          {
            "name": "Organization"
          },
          {
            "name": "OrganizationProfessional"
          },
          {
            "name": "OrganizationSa"
          },
          {
            "name": "Patient"
          },
          {
            "name": "PatientProfessional"
          },
          {
            "name": "PatientUser"
          },
          {
            "name": "PaymentMethod"
          },
          {
            "name": "ProfessionalProvision"
          },
          {
            "name": "Provision"
          },
          {
            "name": "Report"
          },
          {
            "name": "Request"
          },
          {
            "name": "RequestMessage"
          },
          {
            "name": "Specialization"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Professional",
        "possibleTypes": [
          {
            "name": "Assistant"
          },
          {
            "name": "Medic"
          },
          {
            "name": "Operator"
          },
          {
            "name": "OrganizationSa"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "RequestAuthor",
        "possibleTypes": [
          {
            "name": "Medic"
          },
          {
            "name": "Operator"
          },
          {
            "name": "Patient"
          },
          {
            "name": "OrganizationSa"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchPatientUnion",
        "possibleTypes": [
          {
            "name": "PublicPatient"
          },
          {
            "name": "Patient"
          }
        ]
      }
    ]
  }
};
      export default result;
    