import { NotificationDataFragment } from '../generated/graphql';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { NetworkIcon, RequestsIcon } from './icons';
import { parseISO } from 'date-fns';
import { Today } from '@material-ui/icons';

export interface NotificationData {
  title: string;
  message?: string;
  createdAt?: Date;
  icon: ReactNode;
  action?: {
    url: string;
    text: string;
  }
}

function getNotificationUri(url: string) {
  const uri = new URL(url);
  return uri.pathname;
}

export function notificationData(notification: NotificationDataFragment, t: TFunction) {
  switch (notification.name) {
    case 'empty':
      return {
        title: t('no-notifications'),
      }
    case 'network-requested':
      return {
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: t('notification:new-network-request'),
        message: t('notification:new-network-request-message', {
          theRole: notification.data.sender.theRole,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon/>,
        createdAt: parseISO(notification.createdAt),
      }
    case 'network-accepted':
      return {
        title: t('notification:network-request-accepted'),
        message: t('notification:network-request-accepted-message', {
          theRole: notification.data.sender.theRole,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon/>,
        createdAt: parseISO(notification.createdAt),
      }
    case 'request-created':
      return {
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: notification.data.subject,
        message: t('notification:request-created-message', {
          theRole: notification.data.sender.role,
          fullName: notification.data.sender.fullName,
        }),
        icon: <RequestsIcon/>,
        createdAt: parseISO(notification.createdAt),
      }
    case 'request-new-message':
      return {
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: notification.data.subject,
        message: t('notification:request-new-message-message', {
          theRole: notification.data.author.theRole || 'the_patient',
          fullName: notification.data.author.fullName,
        }),
        icon: <RequestsIcon/>,
        createdAt: parseISO(notification.createdAt),
      }
    case 'request-resolved':
      return {
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: t('notification:request-resolved.title'),
        message: t('notification:request-resolved.message', {
          theRole: notification.data.sender.theRole || 'the_patient',
          fullName: notification.data.sender.fullName,
          title: notification.data.request.title,
        }),
        icon: <RequestsIcon/>,
        createdAt: parseISO(notification.createdAt),
      }
    case 'appointment-created-by-professional':
      return {
        title: t('notification:appointment-created-by-professional.title'),
        message: t('notification:appointment-created-by-professional.message', {
          professionalName: notification.data.sender.fullName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
        createdAt: parseISO(notification.createdAt),
        action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined,
      }
    case 'appointment-deleted-by-professional':
      return {
        title: t('notification:appointment-deleted-by-professional.title'),
        message: t('notification:appointment-deleted-by-professional.message', {
          professionalName: notification.data.sender.fullName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
        createdAt: parseISO(notification.createdAt),
      }
  }
}
