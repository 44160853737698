import { FC } from 'react';
import { AppBar, Box, Grid, Link, Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Link as RouteLink } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';

import { getRoute, RoutesKeys } from '../../routes';
import { NavBarProps } from '@hu-care/react-layout';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { useInsets, Insets } from '../../contexts/insets';
import { NotificationMenu } from '../notifications/notification-menu';

const useStyles = makeStyles(theme =>
  createStyles({
    logo: {
      // necessary for content to be below app bar
      height: theme.mixins.toolbar.minHeight,
      width: 'auto',
      padding: '6px 0 0 0',
    },
    logoBox: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    notifications: {
      position: 'absolute',
      right: 0,
    },
    toolbar: (props: Insets) => ({
      paddingTop: props.top || 0,
    }),
    bottomToolbar: (props: Insets) => ({
      boxSizing: 'content-box',
    }),
    titleBar: {
      ...theme.mixins.toolbar,
      // top: theme.mixins.toolbar.minHeight,
      zIndex: 1000,
      backgroundColor: 'white',
      // marginTop: '5px', // TODO check this
      width: '100%',
      position: 'fixed',
      borderBottom: '3px solid #D99EF7',
      boxShadow: '0px 6px 9px #00000029',

      '& *': {
        justifyContent: 'center',
        alignSelf: 'center',
      },
    },
    title: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: '2pt',

      fontSize: 20,

      // '&.medic': {
      //   color: getRoleColor(Role.Medic, theme),
      // },
      // '&.operator': {
      //   color: getRoleColor(Role.Operator, theme),
      // },
      // '&.organization': {
      //   color: getRoleColor(Role.Organization, theme),
      // },
    },
  }),
);

const TopNavBar: FC<{ insets: Insets; showNotifications?: boolean }> = ({ insets, showNotifications = true }) => {
  const classes = useStyles(insets);
  return (
    <AppBar position={'fixed'} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box component="nav" className={classes.logoBox}>
          <Link component={RouteLink} to={getRoute(RoutesKeys.dashboard)}>
            <img src={'/logo_csw_white.svg'} alt={'App Logo'} className={classes.logo}/>
          </Link>
        </Box>
        {showNotifications && (
          <NotificationMenu />
        )}
      </Toolbar>
    </AppBar>
  );
};

export const NavBar: FC<NavBarProps & { showNotifications?: boolean }> = (props) => {
  const insets = useInsets();
  const classes = useStyles(insets);
  const history = useHistory();

  const { pathname } = useLocation();

  return (
    <div>
      <TopNavBar insets={insets} showNotifications={props.showNotifications} />
      {
        pathname !== getRoute(RoutesKeys.updateRequired) &&
        <Toolbar className={clsx(classes.toolbar, classes.bottomToolbar)}/>
      }
      {props.navBar && (
        <Grid container className={classes.titleBar}>
          <Grid item xs={1}>
            {props.showBack
            && <IconButton onClick={history.goBack}>
                <ArrowBackIosIcon  />
            </IconButton>
            }
          </Grid>
          <Grid item xs={10}>
            <div className={clsx(classes.title, props.className)}>
              { props.title
                ? props.title
                : props.getTitle
                  ? props.getTitle({})
                  : ''
              }
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
