import { AppBar, Box, Toolbar, Typography, IconButton } from '@material-ui/core';
import { FC } from 'react';
import { Close } from '@material-ui/icons';
import { UserDataFragment } from '../../generated/graphql';

export const ChatHeader: FC<{ destUser: UserDataFragment; onClose: () => void }> = ({ destUser, onClose }) => {
  return (
    <AppBar position="static" elevation={1}>
      <Toolbar variant="dense">
        <Typography noWrap>
          {destUser.traits.name} {destUser.traits.surname}
        </Typography>
        <Box ml="auto">
          <IconButton size="small" onClick={onClose}>
            <Close color="inherit"/>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
