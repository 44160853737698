import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import { ReactComponent as CalendarAlt } from '../assets/pictures/calendar-alt-regular.svg';

import DateRangeIcon from '@material-ui/icons/DateRange';
import { CircularProgress } from '@material-ui/core';

export const appointmentsRoutes: RouteType[] = [
  {
    key: RoutesKeys.appointments,
    component: asyncLoad(() => import('../containers/appointments/appointments.container')),
    path: '/appointments',
    exact: true,
    config: {
      label: 'Appuntamenti',
      title: 'Appuntamenti e Prestazioni',
      icon: DateRangeIcon,
      altIcon: <CalendarAlt />,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.appointmentDetail,
    component: asyncLoad(() => import('../containers/appointment-detail/appointment-detail.container')),
    path: '/appointments/:appointmentId',
    exact: true,
    config: {
      label: 'Appuntamento',
      getTitle: () => <CircularProgress size={24} />,
      icon: DateRangeIcon,
      altIcon: <CalendarAlt />,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
];
