import {
  CheckCircle,
  FolderShared,
  Mail,
  Event,
  Notifications,
  Home,
  ChatBubble,
  QuestionAnswer,
  Receipt,
  Description,
  Work,
  AccountBox,
  Business,
  Save,
  DeleteForever,
  ExpandMore,
  PersonPin,
  HelpOutline,
  Refresh,
  Done,
  PersonAdd,
  HourglassFull,
  Block,
  Visibility,
  Send,
  Payment,
  Launch,
  Archive,
  Edit,
  Lock,
  Phone,
  FiberManualRecord,
  Clear,
  Telegram,
  Replay,
  Publish,
  CloudDownload,
  RotateLeft,
  ArrowBackIos,
  Cancel,
  GroupAdd,
  Settings,
  People,
} from '@material-ui/icons';

import { IconButton, withStyles } from '@material-ui/core';

import { PatientProfessionalDataFragment, PatientProfessionalStatus } from '../generated/graphql';
import { getProfileIcon } from './profiles';

export const HomeIcon = Home;
export const OrganizationIcon = Business;
export const PatientsIcon = FolderShared;
export const NetworkIcon = People;
export const RequestsIcon = QuestionAnswer;
export const ReportsIcon = Description;
export const InvoicesIcon = Receipt;
export const MailIcon = Mail;
export const ChatIcon = ChatBubble;
export const AgendaIcon = Event;
export const NotificationIcon = Notifications;
export const ImpersonateIcon = PersonPin;
export const SaveIcon = Save;
export const DeleteIcon = DeleteForever;
export const ClearIcon = Clear;
export const ExpandIcon = ExpandMore;
export const ProvisionIcon = Work;
export const ProfileIcon = AccountBox;
export const ClinicIcon = Business;
export const SupportIcon = HelpOutline;
export const AddNetworkIcon = GroupAdd;
export const RefreshIcon = Refresh;
export const DoneIcon = Done;
export const PendingIcon = HourglassFull;
export const BlacklistIcon = Block;
export const SettingsIcon = Settings;
export const ViewIcon = Visibility;
export const SendIcon = Send;
export const PayIcon = Payment;
export const ExternalLinkIcon = Launch;
export const ArchiveIcon = Archive;
export const InvitedPatientIcon = Telegram;
export const EditIcon = Edit;
export const LockIcon = Lock;
export const PhoneIcon = Phone;
export const ClinicRoomIcon = FiberManualRecord;
export const ResetIcon = RotateLeft;
export const ReactivateAgendaListIcon = Replay;
export const AgendaListDraftIcon = EditIcon;
export const AgendaListIcon = Event;
export const PublishAgendaListIcon = Publish;
export const DownloadIcon = CloudDownload;
export const BackIcon = ArrowBackIos;
export const RevokeIcon = Cancel;
export const AddProfileIcon = PersonAdd;

export { ReactComponent as AssistantIcon } from '../assets/icons/user-friends.svg';
export { ReactComponent as PatientIcon } from '../assets/icons/user-injured.svg';
export { ReactComponent as DoctorIcon } from '../assets/icons/user-md.svg';

// custom icon button with reduced padding for adornments
export const AdornmentIconButton = withStyles({
  root: {
    padding: 8,
  },
})(IconButton);

export const getPatientProfessionalIcon = (pp: PatientProfessionalDataFragment) => {
  switch (pp.status) {
    case PatientProfessionalStatus.RequestedByPatient: return <PendingIcon />;
    case PatientProfessionalStatus.RequestedByMedic: return <PendingIcon />;
    case PatientProfessionalStatus.Archived: return <ArchiveIcon />;
    case PatientProfessionalStatus.Approved: return pp.professional
      ? getProfileIcon(pp.professional)
      : <CheckCircle />;
    default: return <div />;
  }
}
