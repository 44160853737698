import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { CircularProgress } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';

export const requestsRoutes: RouteType[] = [
  {
    key: RoutesKeys.requests,
    component: asyncLoad(() => import('../containers/requests/requests.container')),
    path: '/richieste',
    exact: true,
    config: {
      label: 'Richieste',
      title: 'Richieste',
      icon: QuestionAnswerIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.requestDetail,
    component: asyncLoad(() => import('../containers/request-detail/request-detail.container')),
    path: '/richieste/:requestId',
    exact: true,
    config: {
      label: 'Richiesta',
      getTitle: () => <CircularProgress size={24} />,
      icon: QuestionAnswerIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.createRequest,
    component: asyncLoad(() => import('../containers/create-request/create-request.container')),
    path: '/crea-richiesta/:destinationId',
    exact: true,
    config: {
      label: 'Crea richiesta',
      title: 'Crea richiesta',
      icon: QuestionAnswerIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  // {
  //   key: RoutesKeys.createConnection,
  //   component: asyncLoad(() => import('../containers/create-connection/create-connection.container')),
  //   path: '/crea-connessione',
  //   exact: true,
  //   config: {
  //     label: 'Crea connessione',
  //     title: 'Crea connessione',
  //     icon: PersonAddIcon,
  //     navBar: true,
  //     showBack: true,
  //     scopes: [],
  //   },
  // },
  {
    key: RoutesKeys.createFirstConnection,
    component: asyncLoad(() => import('../containers/create-first-connection/create-first-connection.container')),
    path: '/imposta-medico-curante',
    exact: true,
    config: {
      label: 'Imposta medico curante',
      title: 'Imposta medico curante',
      icon: PersonAdd,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
];
