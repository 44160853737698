import clsx from 'clsx';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import { RoutesKeys, routes } from '../../routes';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Grid, SvgIcon } from '@material-ui/core';
import { Insets } from '../../contexts/insets';

const useStyles = makeStyles(theme =>
  createStyles({
    bottomBar: (props: Insets) => ({
      backgroundColor: '#EFEFEF',
      borderTop: '1px solid lightgrey',
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 100000,
      width: '100%',
      height: 70 + (props.bottom || 0),
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: props.bottom || 0,
    }),
    container: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    bottomItem: {
      textDecoration: 'none',
      '&.active *': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& *': {
        color: theme.palette.secondary.light,
      },
      '& p': {
        textAlign: 'center',
        fontSize: '10pt',
        textTransform: 'lowercase',
      },
      '& svg': {
        alignSelf: 'center',
        fontSize: '30px',
        height: '30px',
      },
    },
  }),
);

const bottomLinks = [
  RoutesKeys.dashboard,
  RoutesKeys.appointments,
  RoutesKeys.network,
  RoutesKeys.profile,
]

const bottomRoutes = routes.filter(route => bottomLinks.includes(route.key))
  .sort((a, b) => {
    const indexOfA = bottomLinks.indexOf(a.key);
    const indexOfB = bottomLinks.indexOf(b.key);
    return indexOfA > indexOfB ? 1 : -1;
  });

export default ({ insets }: { insets: Insets }) => {
  const classes = useStyles(insets);
  const { pathname } = useLocation();

  return (
    <Box className={classes.bottomBar}>
      <Container maxWidth="sm" className={classes.container}>
        <Grid container justify="space-between">
          {bottomRoutes.map(route =>
            <Grid item xs className={clsx(classes.bottomItem, {
                active: pathname === route.path,
              })} key={route.key} component={RouteLink} to={route.path}>
                <Grid container direction="column">
                  {
                    route.config.altIcon
                      ? route.config.altIcon
                      : <SvgIcon component={route.config.icon!} />
                  }

                <Typography>
                    { route.config.label }
                </Typography>
                </Grid>
            </Grid>,
          )}
        </Grid>
      </Container>
    </Box>
  );
}
